import React from "react"
import { Link } from "react-router-dom"
import MediaQueries from "../../utils/MediaQueries"

// image
import LogoStamp from "../../assets/logos/logoStamp.svg"

// components
import PartnerButton from "../../components/molecules/PartnerButton"

import styles from "./FirstStep.module.scss"

const FirstStep = () => {
  const { isMobile, isBigScreen } = MediaQueries()

  return (
    <div className={styles.container}>
      <img src={LogoStamp} alt="Selo Empbank" className={styles.image} />

      <h1 className={styles.title}>
        Você está a <span className={styles.titleHighlightSecondary}>três passos</span> de tornar-se um parceiro <span className={styles.titleHighlightPrimary}>Empbank</span>.
      </h1>
      <div className={styles.description}>
        <h5 className={styles.descriptionTitle}>QUASE LÁ</h5>
        <p className={styles.descriptionText}>Para se tornar um parceiro Empank, leia e concorde com os nossos Termos. Em seguida, você será redirecionado para o nosso banco de Cadastros, onde iremos analisar o seu perfil e, após, entraremos em contato para confirmar o seu resultado.</p>
      </div>
      <div className={styles.buttonsContainer}>
        <PartnerButton style={{ width: isMobile || isBigScreen ? "100%" : "300px", marginTop: isMobile ? 0 : 30 }}>
          <Link to="/parceria/2-de-3">COMEÇAR</Link>
        </PartnerButton>
      </div>
    </div>
  )
}

export default FirstStep
