import React from "react"
import { Form, Input } from "antd"

const Signature = ({ setIsSigned, id, isMobile, isTabletOrMobile, isBigScreen }) => {
  const labelStyle = { fontWeight: 700, fontFamily: "Lato, sans-serif" }
  const selectStyle = { fontWeight: 400, color: "#ABB5BE", border: "1px solid #ABB5BE", borderRadius: "8px", padding: 0, fontSize: 16, marginTop: 0 }
  const inputStyle = { ...selectStyle, padding: "5px 10px" }

  const checkSignature = e => {
    if (e.target.value && e.target.value.includes("@") && e.target.value.includes(".")) {
      setIsSigned(true)
    } else {
      setIsSigned(false)
    }
  }

  return (
    <Form id={id} layout={isMobile && "vertical"}>
      <Form.Item label={<p style={{ fontSize: isTabletOrMobile ? "12px" : isBigScreen ? "18px" : "15px", margin: 0 }}>Assinatura eletrônica</p>} colon={false} required className="mb-3 w-100" style={labelStyle}>
        <Input placeholder="Insira seu e-mail" type="email" style={inputStyle} onChange={checkSignature} />
      </Form.Item>
    </Form>
  )
}

export default Signature
