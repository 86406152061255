import React from "react"
import MediaQueries from "../../utils/MediaQueries"

import styles from "./PrivacyPolicyContent.module.scss"

const PrivacyPolicyContent = () => {
  const { isMobile } = MediaQueries()

  return (
    <div style={{ margin: "0 auto", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <style dangerouslySetInnerHTML={{ __html: '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;\n\tpanose-1:0 0 0 0 0 0 0 0 0 0;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1\n\t{margin-top:20.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:20.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh2\n\t{margin-top:.25in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tfont-size:16.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n' }} />
      <div className="WordSection1">
        <h1 align="center" style={{ margin: "0in", textAlign: "center", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            <b>Política de Privacidade</b>
          </span>
        </h1>
        <p style={{ marginTop: "12.0pt" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>&nbsp;</span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Bem-vindo(a)! Obrigado por utilizar a <b>empbank</b>!
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Nós, da <b>Empbank</b>, sociedade empresária inscrita no CNPJ 27.911.069/0001-23, atuamos como correspondente bancário para facilitar o processo de contratação de empréstimos (“Serviços”), por meio de nossa plataforma digital disponível em www.empbank.com.br (“Plataforma”).
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Quando você utiliza a <b>empbank</b>, você nos confia seus dados pessoais. Nos comprometemos a manter essa confiança. Nesse sentido, esta Política de Privacidade (“Política”) explica de maneira clara e acessível como as suas informações e dados serão coletados, usados, compartilhados e armazenados por meio dos nossos sistemas.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>A aceitação desta Política será feita quando o usuário concordar, de forma afirmativa, com o uso dos seus dados pessoais para cada uma das finalidades por nós aqui descritas. Caso não concorde com esta Política, por favor, não continue interagindo com a Plataforma. Todavia, pedimos que nos explique o motivo de discordância para que possamos melhorar nossos produtos.</span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Este documento deve ser lido em conjunto com os nossos Termos de Uso, documento que contém uma visão geral da nossa Plataforma. Caso tenha dúvidas ou precise tratar de qualquer assunto relacionado a esta Política, entre em contato conosco através do e-mail cadastro@empbank.com.br.</span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>1. Quando esta Política se aplica?</span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Essa Política se aplica aos tratamentos de dados pessoais realizados pela <b>empbank</b> para a execução de seus Serviços. Dado pessoal é qualquer informação que identificar direta ou indiretamente alguém (“Dados Pessoais”), como, por exemplo, seu nome, endereço IP e endereço residencial.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>E tratamento é qualquer operação realizada com um Dado Pessoal (“Tratamento”), incluindo a coleta, o armazenamento, o descarte e o compartilhamento desse tipo de informação.</span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank </b> Trata Dados Pessoais para a prestação de seus Serviços. Por exemplo, coletamos certos dados pessoais, relacionados à sua profissão, para simular uma proposta personalizada de empréstimo consignado para você. Este é somente um exemplo que mostra como nossas atividades precisam utilizar Dados Pessoais.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>2. Para quais finalidades utilizamos Dados Pessoais?</span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Durante o uso da Plataforma, o usuário poderá voluntariamente fornecer seus Dados Pessoais quando, por exemplo, solicitar uma proposta de empréstimo consignado. Além disso, o usuário também poderá ter seus Dados Pessoais coletados automaticamente durante sua interação com a Plataforma, por meio de ferramentas que geram análises estatísticas sobre a usabilidade da Plataforma, como informações sobre erro, quantidade de usuários on-line e funcionalidades mais utilizadas.</span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Prezando pela transparência e privacidade de seus Dados Pessoais, listamos abaixo as finalidades para as quais a <b>empbank</b> Trata Dados Pessoais:
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>&nbsp;</span>
        </p>

        <div className={styles.tableContainer}>
          <table border={1} cellSpacing={0} cellPadding={0} maxWidth={isMobile ? "90%" : "592px"} style={{ borderCollapse: "collapse", border: "none", margin: "0 auto" }}>
            <tbody>
              <tr style={{ minHeight: "21.0pt" }}>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "21.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <b>
                      <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Finalidade</span>
                    </b>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "21.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <b>
                      <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Dados Pessoais utilizados</span>
                    </b>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "105.0pt" }}>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Registrar acessos à Plataforma</span>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Conjunto de informações referentes à data e hora de uso da Plataforma a partir de um determinado endereço IP, incluindo os cookies de acesso</span>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "105.0pt" }}>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ marginRight: "19.5pt", textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Enviar simulação personalizada de proposta de empréstimo consignado</span>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Carreira, regime de contratação, CPF, nome completo, data de nascimento, e-mail, celular&nbsp; </span>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "325.0pt" }}>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Contratar empréstimo consignado</span>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Dados cadastrais: nome completo, CPF, dados de endereço completo com CEP, data de nascimento, dados de contato (telefone, e-mail), Informações da fonte pagadora (INSS ou SIAPE). Documentos: documento de identificação, CPF, comprovante de endereço (em alguns casos), selfie, comprovante de margem (extrato de consignação ou contracheque). Análise do comprovante de margem: INSS – Tipo de benefício, valor do benefício mensal, margem disponível, data de emissão do documento, número de contratos ativo, se já possui cartão de crédito ativo no benefício; SIAPE – Órgão pagador, data de emissão do documento, tipo de contrato com o órgão, vigência do benefício (se for pensão), contratos ativos, se já possui cartão de crédito ativo no contracheque, margem disponível</span>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "105.0pt" }}>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Prevenir fraude e garantir a idoneidade da solicitação de empréstimo consignado</span>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Dados cadastrais (indicados acima), documentos (indicados acima), foto (“selfie”) e vídeo contrato</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "189.0pt" }}>
                  <p>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Gerar análises estatísticas (“analytics”) do uso da Plataforma</span>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "189.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Cookies essenciais e de análise (são identificadores eletrônicos que transferimos para o seu navegador ou dispositivo que nos permitem reconhecer seu navegador ou dispositivo e nos informam como e quando a nossa Plataforma é visitada e quantas pessoas as acessam)</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Direcionar publicidade digital</span>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Cookies de marketing e tags (são identificadores eletrônicos que transferimos para o seu navegador ou dispositivo que nos permitem reconhecer seu navegador ou dispositivo e direcionar publicidade personalizada com base na sua interação conosco), relacionando cookies de anúncios (Google e Facebook) com dados de acesso para otimização dos anúncios</span>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "42.0pt" }}>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "42.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Enviar comunicações publicitárias</span>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "42.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Nome, e-mail e telefone</span>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "84.0pt" }}>
                <td maxWidth={260} valign="top" style={{ maxWidth: "195.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "84.0pt" }}>
                  <p>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Atender dúvidas e demandas de suporte</span>
                  </p>
                </td>
                <td maxWidth={332} valign="top" style={{ maxWidth: "249.0pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "84.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
                      Nome, e-mail, CPF e demais informações relacionadas à dúvida ou demanda direcionada à <b>empbank</b>
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p style={{ textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>&nbsp;</span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Nós utilizamos cookies e outras tecnologias de coleta automatizada de dados em nossas plataformas para entender melhor as suas interações conosco e para fornecer um serviço mais adequado às suas necessidades.</span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Caso você queira obter informações mais detalhadas sobre as finalidades citadas acima, poderá entrar em contato com a <b>empbank</b> por meio do canal de contato cadastro@empbank.com.br.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            3. Com quem a <b>empbank</b> pode compartilhar Dados Pessoais?
          </span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Por atuar como correspondente bancária, a <b>empbank</b> precisa operar em conjunto com outras empresas para prestar os seus Serviços. Adotaremos, sempre que for possível, mecanismos para a proteção dos seus Dados Pessoais, visando à preservação da sua privacidade.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Abaixo, listamos as situações nas quais podemos compartilhar seus Dados Pessoais com terceiros:</span>
        </p>
        <p style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Fornecedores: empregamos outras empresas para realizar trabalhos em nosso nome e precisamos compartilhar seus Dados Pessoais com eles para prestar nossos Serviços, incluindo a disponibilização da Plataforma. Por exemplo, usamos serviços de cloud (computação em nuvem) para armazenar a nossa base de dados e compartilhamos seus Dados Pessoais com provedores de tecnologia. Nossos fornecedores somente são autorizados a utilizar os Dados Pessoais para os fins específicos para os quais foram contratados, portanto, eles não irão utilizar os seus Dados Pessoais para outras finalidades, além da prestação de serviços previstos contratualmente.</span>
        </p>
        <p style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Instituições Financeiras: a <b>empbank</b> oferece empréstimos consignados de instituições financeiras parceiras. Assim, para prestar os Serviços a <b>empbank</b> precisa compartilhar Dados Pessoais com as instituições financeiras para que você possa receber propostas, bem como contratar empréstimos consignados.
          </span>
        </p>
        <p style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Correspondentes bancários: a <b>empbank</b> pode atuar em parceria com outros correspondentes bancários. Assim, em determinados casos, Dados Pessoais podem ser compartilhados com esses parceiros para a prestação de nossos Serviços.
          </span>
        </p>
        <p style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Empresas de dados: Alguns dos nossos fornecedores comerciais são empresas de dados, popularmente conhecidas como bureaus, com as quais podemos compartilhar Dados Pessoais para algumas finalidades importantes, como validar a autenticidade de determinado cadastro para prevenção de fraudes.</span>
        </p>
        <p style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Analytics: Os Dados Pessoais armazenados pela <b>empbank</b> podem vir a ser utilizados para fins de analytics (estudos estatísticos), com a finalidade de permitir que a <b>empbank</b> compreenda como os usuários interagem com a Plataforma para possibilitar a melhoria dos Serviços. Estes Dados Pessoais são pseudonimizados e não identificam ou tornam identificáveis os indivíduos a eles relacionados, mas tão somente têm por fim compreender melhor como é a utilização da Plataforma, a fim de melhorar os produtos e serviços oferecidos e melhorar a experiência do usuário.
          </span>
        </p>
        <p style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Marketing e envio de comunicações: a <b>empbank</b> utiliza empresas de marketing e publicidade digital a fim de enviar comunicações, direcionar conteúdo e anúncios de acordo com os interesses dos visitantes e usuários da Plataforma. Para tanto, alguns Dados Pessoais podem ser compartilhados entre a <b>empbank</b>e essas empresas, principalmente identificadores eletrônicos, endereços IP, cookies, dados comportamentais e dados sobre o histórico de consumo, que podem ser utilizados para medir a eficiência da publicidade online.
          </span>
        </p>
        <p style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Para cumprir a lei, resguardar e proteger direitos: A <b>empbank</b> reserva-se o direito de acessar, ler, preservar e divulgar quaisquer Dados Pessoais que acredita serem necessários para cumprir uma obrigação legal ou uma ordem judicial; fazer cumprir esta Política, os Termos de Uso e outros acordos; ou proteger os direitos, propriedade e segurança da <b>empbank</b>, seus colaboradores, usuários e outros, sempre de acordo com a legislação aplicável.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Quando compartilhamos seus Dados Pessoais com qualquer das organizações indicadas acima, limitamos o compartilhamento apenas aos Dados Pessoais necessários para o exercício de suas funções e asseguramos contratualmente que os referidos Dados Pessoais sejam utilizados somente na extensão necessária para a prestação dos serviços em nosso nome ou para a observância dos requisitos legais. Da mesma forma, exigimos que os terceiros com os quais compartilhamos Dados Pessoais se comprometam ao mesmo nível de proteção e privacidade para com os seus Dados Pessoais que a <b>empbank</b> teria se os tivesse tratando diretamente; isso inclui a obrigação de não usar seus Dados Pessoais para qualquer outra finalidade que não a finalidade contratada, além de obrigações de confidencialidade e padrões de segurança da informação, entre outros requisitos legais aplicáveis a contratações desta natureza.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            4. A <b>empbank</b> realiza a transferência internacional de Dados Pessoais?
          </span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Sim. Embora a <b>empbank</b> possua sede no Brasil e os seus produtos e Serviços sejam destinados a pessoas localizadas no Brasil, aplicando-se, portanto, as leis brasileiras relacionadas à proteção de Dados Pessoais, os Dados Pessoais que coletamos podem ser transferidos para os Estados Unidos da América. Essa transferência ocorre, principalmente, para fins de hospedagem em nuvem dos servidores da <b>empbank</b>, o que é realizado por empresas contratadas pela <b>empbank</b> para essa finalidade.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Essas empresas podem ser identificadas neste link. Recomendamos que você leia atentamente as políticas de privacidade desses fornecedores, que podem identificar a localização exata de suas sedes e servidores.</span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Ao fornecer Dados Pessoais para a <b>empbank</b>, o usuário concorda com o tratamento e a transferência internacional de tais Dados Pessoais para os Estados Unidos da América. Estes Dados Pessoais poderão estar sujeitos à legislação local e às regras pertinentes.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>5. Quais são seus direitos?</span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>A legislação brasileira garante direitos relacionados à privacidade e à proteção dos seus Dados Pessoais. Queremos que você tenha acesso e conhecimento de todos os direitos relativos ao Tratamento de seus Dados Pessoais, que são os seguintes:</span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>&nbsp;</span>
        </p>

        <div style={{ display: "flex", justifyContent: "center" }} className={styles.tableContainer}>
          <table className={1} border={1} cellSpacing={0} cellPadding={0} width={isMobile ? "90%" : 592} style={{ borderCollapse: "collapse", border: "none" }}>
            <tbody>
              <tr style={{ minHeight: "21.0pt" }}>
                <td width={237} valign="top" style={{ maxWidth: "177.75pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "21.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <b>
                      <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Direito</span>
                    </b>
                  </p>
                </td>
                <td maxWidth={355} valign="top" style={{ maxWidth: "266.25pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "21.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <b>
                      <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Explicação</span>
                    </b>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "105.0pt" }}>
                <td maxWidth={237} valign="top" style={{ maxWidth: "177.75pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ marginRight: "15.75pt" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Confirmação do Tratamento de seus Dados Pessoais</span>
                  </p>
                </td>
                <td maxWidth={355} valign="top" style={{ maxWidth: "266.25pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Este direito permite que você possa requisitar e receber uma confirmação sobre a existência de Tratamento de seus Dados Pessoais.</span>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "84.0pt" }}>
                <td maxWidth={237} valign="top" style={{ maxWidth: "177.75pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "84.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Acesso aos Dados Pessoais</span>
                  </p>
                </td>
                <td maxWidth={355} valign="top" style={{ maxWidth: "266.25pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "84.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
                      Este direito permite que você possa requisitar e receber uma cópia dos Dados Pessoais tratados pela <b>empbank</b>.
                    </span>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "105.0pt" }}>
                <td maxWidth={237} valign="top" style={{ maxWidth: "177.75pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ marginRight: "24.75pt", textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Correção de Dados Pessoais incompletos, inexatos ou desatualizados</span>
                  </p>
                </td>
                <td maxWidth={355} valign="top" style={{ maxWidth: "266.25pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "105.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Este direito permite que você solicite a correção e/ou retificação dos Dados Pessoais, caso identifique que alguns deles estão incorretos.</span>
                  </p>
                </td>
              </tr>
              <tr style={{ minHeight: "240.0pt" }}>
                <td maxWidth={237} valign="top" style={{ maxWidth: "177.75pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "240.0pt" }}>
                  <p style={{ marginRight: "29.25pt" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Anonimização, bloqueio ou eliminação dos Dados Pessoais desnecessários, excessivos ou tratados de forma incorreta</span>
                  </p>
                </td>
                <td maxWidth={355} valign="top" style={{ maxWidth: "266.25pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "240.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
                      Este direito permite que você solicite a anonimização, o bloqueio ou a eliminação dos dados pessoais da base de dados da <b>empbank</b>. Os seus dados poderão ser anonimizados, bloqueados ou eliminados dos servidores quando assim requisitado ou quando estes não forem mais necessários ou relevantes para o oferecimento dos Serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual necessidade de retenção dos dados para o cumprimento de uma obrigação legal ou para resguardo de direitos da empbank ou de terceiros.
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td maxWidth={237} valign="top" style={{ maxWidth: "177.75pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "150.0pt" }}>
                  <p style={{ marginRight: "33.75pt", textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Portabilidade dos Dados Pessoais</span>
                  </p>
                </td>
                <td maxWidth={355} valign="top" style={{ maxWidth: "266.25pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "150.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>A empbank permite que você ou terceiros por você nomeados obtenham acesso aos Dados Pessoais Tratados pela empbank em formato estruturado e interoperável, desde que isso não viole a nossa propriedade intelectual ou nossos segredos de negócio.</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td maxWidth={237} valign="top" style={{ maxWidth: "177.75pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "200.0pt" }}>
                  <p style={{ marginRight: "33.75pt", textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Não fornecer ou revogar o consentimento a qualquer momento</span>
                  </p>
                </td>
                <td maxWidth={355} valign="top" style={{ maxWidth: "266.25pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "200.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Você tem o direito de não fornecer consentimento quando este for requisitado. Igualmente, após consentir, você também tem o direito de revogar o seu consentimento, no entanto, isso não afetará a legalidade de qualquer Tratamento anteriormente realizado. Se o usuário não fornecer ou revogar o seu consentimento, é possível que a empbank e os parceiros empbank não possam fornecer-lhe determinados produtos ou serviços.</span>
                  </p>
                </td>
              </tr>
              <tr>
                <td maxWidth={237} valign="top" style={{ maxWidth: "177.75pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "auto", marginBottom: "30.0pt" }}>
                  <p style={{ marginRight: "33.75pt", textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Se opor a determinado tratamento de dados</span>
                  </p>
                </td>
                <td maxWidth={355} valign="top" style={{ maxWidth: "266.25pt", border: "none", padding: "0in 0in 0in 0in", minHeight: "auto", marginBottom: "30.0pt" }}>
                  <p style={{ textAlign: "justify" }}>
                    <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
                      Você também tem o direito de se opor a determinadas finalidades de Tratamento. Em alguns casos, a empbank pode demonstrar que tem motivos legítimos seus ou de parceiros para Tratar os Dados Pessoais, os quais podem se sobrepor à oposição ao Tratamento, caso, por exemplo, sejam estritamente essenciais para a prestação dos Serviços, para o cumprimento de obrigações legais ou regulatórias, ou mesmo para o resguardo de direitos da <b>empbank</b> ou de terceiros.
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p style={{ textAlign: "justify", marginTop: "50.0pt" }}>
          <b>
            <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>AVISOS IMPORTANTES</span>
          </b>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            PARA SUA SEGURANÇA, SEMPRE QUE VOCÊ APRESENTAR UMA REQUISIÇÃO PARA EXERCER SEUS DIREITOS, A <b>EMPBANK</b> PODERÁ SOLICITAR ALGUMAS INFORMAÇÕES E/OU DOCUMENTOS COMPLEMENTARES PARA QUE POSSAMOS COMPROVAR A SUA IDENTIDADE, BUSCANDO IMPEDIR FRAUDES. FAZEMOS ISSO PARA GARANTIR A SEGURANÇA E A PRIVACIDADE DE TODOS.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            EM ALGUNS CASOS, A<b>EMPBANK</b> PODE TER MOTIVOS LEGÍTIMOS PARA DEIXAR DE ATENDER A UMA SOLICITAÇÃO DE EXERCÍCIO DE DIREITOS. ESSAS SITUAÇÕES INCLUEM, POR EXEMPLO, CASOS EM QUE UMA REVELAÇÃO DE INFORMAÇÕES ESPECÍFICAS PODERIA VIOLAR DIREITOS DE PROPRIEDADE INTELECTUAL OU SEGREDOS DE NEGÓCIO DA <b>EMPBANK</b> OU DE TERCEIROS, BEM COMO CASOS EM QUE PEDIDOS DE EXCLUSÃO DE DADOS NÃO POSSAM SER ATENDIDOS EM RAZÃO DA EXISTÊNCIA DE OBRIGAÇÃO DA <b>EMPBANK</b> DE RETER DADOS, SEJA PARA CUMPRIR OBRIGAÇÕES LEGAIS, REGULATÓRIAS OU PARA POSSIBILITAR A DEFESA DA <b>EMPBANK</b> OU DE TERCEIROS EM DISPUTAS DE QUALQUER NATUREZA.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            AINDA, ALGUMAS SOLICITAÇÕES PODEM NÃO SER RESPONDIDAS DE FORMA IMEDIATA, MAS A <b>EMPBANK</b> SE COMPROMETE A RESPONDER TODAS AS REQUISIÇÕES EM UM PRAZO RAZOÁVEL E SEMPRE EM CONFORMIDADE COM A LEGISLAÇÃO APLICÁVEL.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Você pode tirar dúvidas relacionadas ou exercer esses direitos a qualquer tempo, por meio do e-mail cadastro@empbank.com.br.</span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>6. Por quanto tempo seus Dados Pessoais serão armazenados?</span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> armazenará Dados Pessoais somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coleta, inclusive para fins de cumprimento de quaisquer obrigações legais (como a de guarda dos registros de acesso à aplicação por 6 meses, conforme Marco Civil da Internet), contratuais, de prestação de contas ou requisição de autoridades competentes.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Para determinar o período de retenção adequado para os Dados Pessoais, a <b>empbank</b> considera a quantidade, a natureza e a sensibilidade de tais Dados, o risco potencial de danos decorrentes do uso não autorizado ou da divulgação de seus Dados Pessoais, a finalidade do Tratamento e eventual possibilidade de se alcançar tais propósitos por outros meios, e os requisitos legais aplicáveis.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Seus Dados Pessoais também poderão ser mantidos caso a sua manutenção se faça necessária para resguardar os direitos da <b>empbank</b> ou de terceiros, especialmente caso estes sejam necessários para defesas em disputas ou litígios de qualquer natureza.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            7. Como a <b>empbank</b> protege os seus Dados Pessoais?
          </span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> se esforça para proteger a privacidade da conta e dos Dados Pessoais dos usuários que mantém em seus registros. Mantemos salvaguardas de caráter procedimental, técnico e físico que nos ajudam a evitar a perda, uso indevido ou acesso não autorizado, divulgação, alteração ou destruição dos Dados pessoais fornecidos pelos usuários. Dentre as medidas que tomamos, destacamos controle de acesso, criptografia, detecção de vulnerabilidades, prevenção de ataques e segregação de dados.
          </span>
        </p>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            No entanto, não é possível garantir que todas as transmissões de Dados Pessoais realizadas via Internet sejam completamente seguras. É possível que terceiros que não estejam sob o nosso controle interceptem ou tenham acesso às transmissões ou aos Dados Pessoais de maneira ilegal. Portanto, não sendo possível garantir a completa segurança dos Dados Pessoais transmitidos ao nosso site, pedimos sua colaboração para nos ajudar a manter um ambiente seguro para todos. Caso identifique ou tome conhecimento de algo que comprometa a segurança dos seus Dados Pessoais, pedimos que entre em contato com a <b>empbank</b> por meio do seguinte canal de contato: cadastro@empbank.com.br.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            8. Como falar com a <b>empbank</b>?
          </span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>Se você acredita que seus Dados Pessoais foram usados de maneira incompatível com esta Política ou com as suas escolhas, ou, ainda, se você tiver outras dúvidas, comentários ou sugestões relacionadas a esta Política, entre em contato conosco, por meio dos seguintes canais de contato:</span>
        </p>
        <p style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>DPO (encarregado): Comitê de Proteção de Dados</span>
        </p>
        <p style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>E-mail: seguranca@empbank.com.br .</span>
        </p>
        <p style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>SAC: (43) 3024-1570</span>
        </p>
        <p style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>&nbsp;</span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>9. Mudanças na Política de Privacidade</span>
        </h2>
        <p style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Como a <b>empbank</b> está sempre buscando melhorar seus serviços, essa Política de Privacidade pode passar por atualizações. Desta forma, recomendamos que o usuário visite periodicamente esta página para ter conhecimento sobre as modificações. Caso sejam feitas alterações relevantes que necessitem de um novo consentimento, iremos publicar essa atualização e solicitar um novo consentimento.
          </span>
        </p>
        <p>
          <span style={{ fontFamily: "Roboto" }}>&nbsp;</span>
        </p>
        <p>
          <span>&nbsp;</span>
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicyContent
