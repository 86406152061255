import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { Form } from "react-bootstrap"
import MediaQueries from "../../utils/MediaQueries"

// image
import LogoStamp from "../../assets/logos/logoStamp.svg"

// components
import PartnerButton from "../../components/molecules/PartnerButton"
import Signature from "./Signature"
import PartnershipTerm from "./PartnershipTerm"

import styles from "./SecondStep.module.scss"

const SecondStep = () => {
  const [firstCheckboxActive, setFirstCheckboxActive] = useState(false)
  const [secondCheckboxActive, setSecondCheckboxActive] = useState(false)
  const [isSigned, setIsSigned] = useState(false)

  const history = useHistory()
  const { isMobile, isTabletOrMobile, isBigScreen } = MediaQueries()

  const disabled = !firstCheckboxActive || !secondCheckboxActive || !isSigned

  const handleNext = () => {
    history.push("/parceria/3-de-3")
  }

  return (
    <div className={styles.container}>
      <img src={LogoStamp} alt="Selo Empbank" className={styles.image} />

      <h1 className={styles.title}>
        Você está a <span className={styles.titleHighlightSecondary}>dois passos</span> de tornar-se um parceiro <span className={styles.titleHighlightPrimary}>Empbank</span>.
      </h1>

      <div className={styles.contractBox}>
        <div className={styles.titleContainer}>
          <h2 className={styles.innerTitle}>Termo de parceria</h2>

          <p className={styles.description}>Role para baixo para ler o Termo de Parceria e, ao final, selecione a opção ”Li e aceito o Termo de Parceria descrito acima”. Para habilitar o botão de avançar, assine eletronicamente com o seu e-mail e selecione também a opção "Li e aceito os Termos de Uso e Política de Privacidade".</p>
        </div>
        <div className={styles.contractWrapper}>
          <div className={styles.contractContainer}>
            <div className={styles.contract}>
              <PartnershipTerm />
            </div>
            <div className={styles.checkboxContainer} style={{ justifyContent: "center", paddingBottom: "20px", marginTop: "20px" }}>
              <Form.Check type="checkbox" checked={firstCheckboxActive} onChange={() => setFirstCheckboxActive(!firstCheckboxActive)} />
              <span>Li e aceito o Termo de Parceria descrito acima</span>
            </div>
          </div>
        </div>

        <div className={styles.boxFooter}>
          <div className={styles.signature}>
            <Signature setIsSigned={setIsSigned} isMobile={isMobile} isTabletOrMobile={isTabletOrMobile} isBigScreen={isBigScreen} id="signature" />
          </div>
          <div className={styles.checkboxes}>
            <div className={styles.checkboxContainer}>
              <Form.Check type="checkbox" checked={secondCheckboxActive} onChange={() => setSecondCheckboxActive(!secondCheckboxActive)} />
              <span>
                Li e aceito os <Link to="/termos-de-uso">Termos de Uso</Link> e <Link to="/política-de-privacidade">Política de Privacidade</Link>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <PartnerButton style={{ width: isMobile && !isBigScreen ? "100%" : "300", marginTop: 50, borderRadius: 40, transition: "background-color 1s ease-in-out" }} disabled={disabled} form="signature" onClick={handleNext}>
          AVANÇAR
        </PartnerButton>
      </div>
    </div>
  )
}

export default SecondStep
