import React from "react"
import { useAuth0 } from "@auth0/auth0-react"

// components
import Button from "../atoms/Button/Button"

const LoginButton = ({ style, text }) => {
  const { loginWithRedirect } = useAuth0()
  return (
    <Button
      variant="loginSecondary"
      onClick={() =>
        // loginWithRedirect({
        //   redirectUri: "https://eager-varahamihira-f77f74.netlify.app/area-do-parceiro/"
        // })
        loginWithRedirect()
      }
      style={style}
    >
      {text}
    </Button>
  )
}

export default LoginButton
