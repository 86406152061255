import React from "react"
import { Link } from "react-router-dom"
import MediaQueries from "../../utils/MediaQueries"

// image
import PartnerImage from "../../assets/images/partner-image.png"

// components
import B2BCustomerButton from "../../components/molecules/B2BCustomerButton"
import PartnerButton from "../../components/molecules/PartnerButton"

import styles from "./Partner.module.scss"

const Partner = () => {
	const { isMobile, isBigScreen } = MediaQueries()

	const handleB2BContact = () => {
		window.open("https://wa.me/5543984236803")
	}

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<h1 className={styles.title}>
					Ofereça os serviços <span className={styles.titleHighlightPrimary}>Empbank</span> ao seu <span className={styles.titleHighlightSecondary}>cliente</span>.
				</h1>
				<div className={styles.description}>
					<h5 className={styles.descriptionTitle}>COMO FUNCIONA</h5>
					<p className={styles.descriptionText}>Tornando-se uma Empresa Parceira da Empbank, você pode oferecer nossos serviços aos seus clientes, com vantagens exclusivas e consultoria da nossa equipe. Ofereça parcelamento que cabem no bolso do seu cliente e receba à vista com segurança. Aproveite as vantagens de fazer parte da nossa rede de parceiros: efetue o cadastro e descubra o que nossos parceiros acham sobre o nosso serviço.</p>
				</div>
				<div className={styles.buttonsContainer}>
					<B2BCustomerButton onClick={handleB2BContact} style={{ margin: isMobile ? "0 0 5px 0" : "0 5px 0 0", fontSize: isMobile && "19px" }}>
						ATENDIMENTO EMPRESAS
					</B2BCustomerButton>
					<PartnerButton>
						<Link to="/parceria/1-de-3">QUERO SER PARCEIRO</Link>
					</PartnerButton>
				</div>
			</div>
			{!isBigScreen && <div className={styles.break}></div>}

			<div className={styles.rightContainer}>
				<img src={PartnerImage} alt="Duas mãos cumprimentando-se" className={styles.image} />
			</div>
		</div>
	)
}

export default Partner
