import React, { useState } from "react"
import { Link } from "react-router-dom"
import MediaQueries from "../../utils/MediaQueries"

// components
import BuyButton from "../../components/molecules/BuyButton"
import PartnerProductButton from "../../components/molecules/PartnerProductButton"

// image
import PartnerAreaImage from "../../assets/images/partnerarea-image.png"
import PartnerAreaImageMobile from "../../assets/images/partnerarea-image-mobile.png"

import styles from "./Products.module.scss"

const Products = () => {
  const [active, setActive] = useState(null)
  const { isTabletOrMobile } = MediaQueries()

  const products = [
    {
      title: "Recover",
      subtitle: "Recupere potenciais clientes",
      paragraphs: [
        {
          descriptionTitle: "O que é:",
          descriptionText: "Ajudamos você a recuperar clientes que não puderam contratar seus serviços por falta de dinheiro. Verificamos para você as condições liberadas para cada indivíduo de seu interesse."
        },
        {
          descriptionTitle: "Como funciona:",
          descriptionText: "Você nos fornece a lista de CPFs que quer consultar. Retornamos a lista com as condições de crédito liberadas para o respectivo CPF. Você entra em contato com o cliente reoferecendo o serviço."
        },
        {
          descriptionTitle: "Planos:",
          descriptionText: "Selecione o plano ideal para você"
        }
      ],
      exampleHeader: "CPF: 123.456.789-10",
      exampleBody: ["Potencial: 17.000,00", "Orçamento: R$6.500,00", "Prazo 84x", "Parcela de R$159,25"],
      options: [
        ["até 100 CPF", "R$1,30", "por CPF"],
        ["até 200 CPF", "R$1,00", "por CPF"],
        ["200+ CPF", "R$0,85", "por CPF"]
      ]
    },
    {
      title: "Campanha Direcionada",
      subtitle: "Ofereça para o público mais amplo do Brasil: Consignado",
      paragraphs: [
        {
          descriptionTitle: "O que é:",
          descriptionText: "Ajudamos você com o contato de pessoas de acordo com o perfil de sua preferência, que tenham o perfil consignado. Quer vender mais? Então faça o ativo. Ligue para pacientes que tenham o perfil consignado na sua cidade. São 48 milhões de pessoas com perfil consignado. Atende a não bancarizados e a negativados."
        },
        {
          descriptionTitle: "Como funciona:",
          descriptionText: "Você escolhe seu público. Retornamos CPFs com informações básicas para você fazer a prospecção."
        },
        {
          descriptionTitle: "Planos:",
          descriptionText: "Selecione o plano ideal para você"
        }
      ],
      exampleHeader: "PÚBLICO",
      exampleBody: ["INSS", "SERVIDOR ESTADUAL", "SERVIDOR FEDERAL", "EXÉRCITO", "MARINHA", "AERONÁUTICA"],
      options: [
        ["até 300 CPF", "R$2,50", "por CPF"],
        ["até 1.000 CPF", "R$2,00", "por CPF"],
        ["1.000+ CPF", "R$1,70", "por CPF"]
      ]
    }
  ]

  const activePriceStyle = {
    backgroundColor: "#06b27d",
    border: "0.78px solid #06b27d",
    color: "#ffffff",
    fontWeight: 700
  }

  const handleBuyBtnClick = () => {
    window.open("https://wa.me/5543984236803")
  }

  return (
    <div className={styles.container}>
      {!isTabletOrMobile ? <img src={PartnerAreaImage} alt="Duas mãos cumprimentando-se" className={styles.image} /> : <img src={PartnerAreaImageMobile} alt="Duas mãos cumprimentando-se" className={styles.image} />}

      <div className={styles.slogan}>CRÉDITO PARA O SEU CLIENTE, VENDAS PARA A SUA EMPRESA</div>

      <div className={styles.productsContainer} id="products">
        {products.map(product => {
          return (
            <div className={styles.product} key={product.title}>
              <div className={styles.titleContainer}>
                <h1 className={styles.productTitle}>{product.title}</h1>
                <p className={styles.productSubtitle}>{product.subtitle}</p>
              </div>

              <div className={styles.descriptionContainer} style={{ height: 400 }}>
                {product.paragraphs.slice(0, 2).map(paragraph => {
                  return (
                    <div key={paragraph.descriptionTitle}>
                      <div className={styles.descriptionTitle}>{paragraph.descriptionTitle}</div>
                      <div className={styles.descriptionText}>{paragraph.descriptionText}</div>
                    </div>
                  )
                })}
                <div className={styles.example}>
                  <p className={styles.exampleHeader}>{product.exampleHeader}</p>
                  {product.exampleBody.map(exampleBodyParagraph => {
                    return (
                      <p key={exampleBodyParagraph} className={styles.exampleBody}>
                        {exampleBodyParagraph}
                      </p>
                    )
                  })}
                </div>
              </div>

              <div className={styles.descriptionContainer} style={{ marginTop: 50 }}>
                <div className={styles.descriptionTitle}>{product.paragraphs[2].descriptionTitle}</div>
                <div className={styles.descriptionText}>{product.paragraphs[2].descriptionText}</div>
              </div>

              <div className={styles.optionsContainer}>
                {product.options.map((option, index) => {
                  console.log(`${option[1]} ${product.title}`)
                  return (
                    <div className={styles.option} key={index}>
                      <p className={styles.optionDescription} style={{ marginBottom: 8.5 }}>
                        {option[0]}
                      </p>
                      <PartnerProductButton variant="partnerProduct" style={active === `${option[1]} ${product.title}` ? activePriceStyle : null} onClick={() => setActive(`${option[1]} ${product.title}`)}>
                        {option[1]}
                      </PartnerProductButton>
                      <p className={styles.optionDescription} style={{ marginBottom: 3.5 }}>
                        {option[2]}
                      </p>
                    </div>
                  )
                })}
              </div>
              <div className={styles.buyBtnContainer}>
                <BuyButton variant="buyBtn" className={styles.buyBtn} disabled={!(active?.split(" ")[1] === product.title.split(" ")[0])} onClick={handleBuyBtnClick} />

                <p className={styles.questions}>
                  Dúvidas?
                  <span className={styles.questionsLink}>
                    <Link to="/contato"> Consulte nossa equipe.</Link>
                  </span>
                </p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Products
