import React from "react"

// components
import TermsOfUseContent from "./TermsOfUseContent"

import styles from "./TermsOfUse.module.scss"

const TermsOfUse = () => {
  return (
    <div className={styles.container}>
      <TermsOfUseContent />
    </div>
  )
}

export default TermsOfUse
