import React from "react"

const TermsOfUseContent = () => {
  return (
    <div style={{ backgroundColor: "transparent" }}>
      <style dangerouslySetInnerHTML={{ __html: '\n<!--\n /* Font Definitions */\n @font-face\n\t{font-family:"Cambria Math";\n\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n@font-face\n\t{font-family:Roboto;\n\tpanose-1:0 0 0 0 0 0 0 0 0 0;}\n /* Style Definitions */\n p.MsoNormal, li.MsoNormal, div.MsoNormal\n\t{margin:0in;\n\tline-height:115%;\n\tfont-size:11.0pt;\n\tfont-family:"Arial",sans-serif;}\nh1\n\t{margin-top:20.0pt;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:20.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\nh2\n\t{margin-top:.25in;\n\tmargin-right:0in;\n\tmargin-bottom:6.0pt;\n\tmargin-left:0in;\n\tline-height:115%;\n\tpage-break-after:avoid;\n\tfont-size:16.0pt;\n\tfont-family:"Arial",sans-serif;\n\tfont-weight:normal;}\n.MsoChpDefault\n\t{font-family:"Arial",sans-serif;}\n.MsoPapDefault\n\t{line-height:115%;}\n@page WordSection1\n\t{size:8.5in 11.0in;\n\tmargin:1.0in 1.0in 1.0in 1.0in;}\ndiv.WordSection1\n\t{page:WordSection1;}\n /* List Definitions */\n ol\n\t{margin-bottom:0in;}\nul\n\t{margin-bottom:0in;}\n-->\n' }} />
      <div className="WordSection1">
        <h1 align="center" style={{ margin: "0in", textAlign: "center", pageBreakAfter: "auto" }}>
          <b>
            <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
              Termos de Uso
            </span>
          </b>
        </h1>
        <p className="MsoNormal">
          <span lang="EN" style={{ fontFamily: "Roboto" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN" style={{ fontFamily: "Roboto" }}>
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <i>
            <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
              empbank.com.br
            </span>
          </i>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            {" "}
            pertence à <b>Empbank</b>, sociedade empresária inscrita no CNPJ 27.911.069/0001-23, com sede na Rua Prefeito Hugo Cabral, 136 – Sala 01 – Centro – Londrina – PR – CEP 86020-110. A <b>empbank</b> não é uma instituição financeira. Nós atuamos como correspondente bancário (nos termos da Resolução 3.594/11 do Banco Central do Brasil) e, nesse sentido, intermediamos o processo de contratação de empréstimos pessoais junto às instituições financeiras parceiras. Estabelece as presentes condições de uso para a utilização do Site (“Termo de Uso”):
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <b>
            <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
              Das Definições
            </span>
          </b>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Os termos constantes neste Termo de Uso, sempre que usados com a primeira letra em maiúsculo, terão o significado estabelecido abaixo, seja no plural ou no singular:
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Parceiros: significam as instituições indicadas na página de parceiros, onde também constam as informações relacionadas a cada um dos Parceiros e os respectivos telefones para atendimento. As informações coletadas para cadastro são as indicadas na nossa&nbsp; Política de Privacidade.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Aceitar ou Aceite: significa o ato do Usuário realizar ações no site seguidas do aviso de “Li e aceito os Termos de Uso e Política de Privacidade” disposta no Site. Tal ato implica no consentimento prévio, expresso, livre e informado do Usuário em relação a todo o disposto em tais documentos.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Conteúdo: significa toda e qualquer informação disponibilizada pelo ou por meio do Site, tais como textos, dados, software, imagens, vídeos, áudios, recursos interativos, etc., incluindo-se os códigos fontes empregados para exibição desses conteúdos, como aqueles em linguagem HTML, CSS, PHP, entre outros.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Conta <b>empbank</b>: significa a conta de acesso do Usuário à <b>empbank</b>. Esta conta é automaticamente criada e vinculada ao Usuário quando este fornece seus dados pessoais à <b>empbank</b> para receber uma cotação de crédito, quando também realiza o Aceite.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Dados de Conta: significa qualquer dado do Usuário coletado pela <b>empbank</b> diretamente nos sites de instituições financeiras e demais instituições autorizadas a funcionar pelo Banco Central do Brasil ("instituições financeiras”), que detalhe, de forma específica e pessoal, informações sobre a(s) sua(s) conta(s), incluindo, sem limitação, renda mensal do Usuário, natureza e tipo de suas despesas, dívidas e empréstimos, margem consignável junto ao empregador, histórico de desconto de empréstimos consignados, entre outros dados relevantes ao planejamento financeiro do Usuário, incluindo, ainda, dados que tenham sido inseridos pelo Usuário em sua Conta <b>empbank</b> para individualização de sua experiência.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Dados Financeiros Pessoais: significa qualquer dado do Usuário disponibilizado pelas instituições financeiras.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Dados Pessoais: significa qualquer dado disponibilizado pelo Usuário que, de alguma forma, o identifique, tais como, mas não se limitando a, nome, CPF, endereço, número de telefone ou endereço de email.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Política de Privacidade: significa a política de privacidade que rege as disposições sobre a utilização dos dados do Usuário.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Site: significa o endereço eletrônico www.empbank.com.br ou qualquer outro que vier a substituí-lo.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Software: significa o software de propriedade exclusiva da <b>empbank</b> por meio do qual serão obtidos os Dados Financeiros Pessoais e de Gestão do Usuário diretamente nos sites das instituições&nbsp; e de gestão de recursos humanos, bem como geridos e manejados todos os dados do Usuário, sempre de maneira automatizada.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Usuário: significa uma pessoa natural, com capacidade civil ou jurídica, com plena capacidade de contratar, que acessa o Site e realiza o seu cadastro pessoal de modo a usufruir das funcionalidades oferecidas pela <b>empbank</b>, aderindo desta forma automaticamente ao presente Termo de Uso e à Política de Privacidade.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <b>
            <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
              Da Adesão
            </span>
          </b>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Para a realização do cadastro e utilização das funcionalidades do Software é obrigatória a leitura, compreensão e Aceite do presente Termo de Uso e da Política de Privacidade pelo Usuário. O ACEITE DO TERMO DE USO E DA POLÍTICA DE PRIVACIDADE IRÁ IMPLICAR O RECONHECIMENTO, PELO USUÁRIO, DE QUE ELE LEU E ENTENDEU TODOS OS TERMOS CONSTANTES NESTES DOCUMENTOS. CASO O USUÁRIO TENHA QUALQUER DÚVIDA SOBRE O TERMO DE USO E/OU A POLÍTICA DE PRIVACIDADE, A <b>EMPBANK</b> RECOMENDA QUE O USUÁRIO ENTRE EM CONTATO COM A <b>empbank</b> ANTES DE ACEITAR ESTAR SUJEITO AOS MESMOS.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A partir do momento em que o Usuário Aceitar o Termo de Uso e a Política de Privacidade, as disposições neles constantes serão aplicáveis e irão regular plenamente a relação entre a <b>empbank</b> e o Usuário. Dessa forma, é recomendável eventual impressão de uma cópia dos mesmos para futura referência. Será dever do Usuário manter-se atento a possíveis atualizações do Termo de Uso e da Política de Privacidade, que podem ocorrer a qualquer tempo.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Da Comunicação com a <b>empbank</b>
          </span>
        </h2>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Para qualquer assunto relacionado às condições deste Termo de Uso ou da Política de Privacidade, bem como ao Site/Conteúdo/Software o Usuário deverá entrar em contato com a <b>empbank</b> por meio do e-mail{" "}
          </span>
          <span lang="EN">
            <span style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto", color: "windowtext", textDecoration: "none" }}>cadastro@empbank.com.br</span>
          </span>
          <b>
            <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto", background: "white" }}>
              {" "}
            </span>
          </b>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Da Utilização do Software
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Este Termo de Uso concede ao Usuário uma licença pessoal, mundial, revogável, não exclusiva e intransferível de uso do Software sem cobrança de royalties, sendo certo que o Usuário não poderá utilizar e nem permitir que terceiros utilizem o Software para qualquer outra finalidade não expressamente prevista neste Termo de Uso. Dessa forma, não será permitido ao Usuário, sem qualquer limitação, copiar, modificar, distribuir, vender ou alugar os serviços disponibilizados pela <b>empbank</b>e viabilizados pelo Software.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Para poder usar os serviços da <b>empbank</b> o Usuário deverá previamente realizar o seu cadastro no Site, atos nos quais o Usuário fornecerá à empbank os seus Dados Pessoais, nome de usuário (“Login”) e senha que serão utilizados para acesso a Conta <b>empbank</b>
            do Usuário.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário será responsável pela veracidade, validade e precisão dos Dados Pessoais ou Dados de Conta por ele fornecidos no seu cadastro, inclusive em relação à indicação de endereço de e-mail válido de sua titularidade, competindo-lhe, ainda, manter referido cadastro sempre atualizado. A <b>empbank</b> e não terá qualquer obrigação de policiar ou fiscalizar os Dados Pessoais ou Dados de Conta fornecidos pelos Usuários, mas poderá, a seu exclusivo critério, excluir as informações que lhe pareçam inverídicas ou ofensivas.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A senha e o Login criados pelo Usuário para acesso a sua Conta <b>empbank</b> são confidenciais e de responsabilidade exclusiva do Usuário, que deverá entrar em contato com a <b>empbank</b>, imediatamente, na hipótese de comprometimento do seu sigilo. A <b>empbank</b> não recomenda o uso, pelo Usuário, da mesma senha para diversos sites na internet/propósitos nem a inserção de Dados Pessoais ou Dados de Conta como parte da senha.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário está plenamente ciente e concorda que, ao fornecer os seus Dados Pessoais e os seus Dados de Conta à <b>empbank</b> autoriza a <b>empbank</b> a usar o Software para buscar Dados Financeiros Pessoais junto a cada instituição financeira e sistemas de gestão de crédito e recursos humanos informados pelo Usuário, sempre de maneira automatizada. A <b>empbank</b> irá então utilizar o Software para organizar tais dados, a fim de atender o propósito da <b>empbank</b>, bem como usar tais dados nos termos da Política de Privacidade.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A utilização do Software pelo Usuário possui caráter pessoal e intransferível, unicamente para fins lícitos e relacionados ao propósito a que o Site se destinam, nos termos deste Termo de Uso. Em nenhuma hipótese, o Usuário terá acesso ao código fonte do Software ora licenciado, já que o código fonte do Software é confidencial e de titularidade exclusiva da <b>empbank</b>, que é a detentora de todos os direitos de propriedade intelectual dele inerentes.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <b>
            <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
              Dados de Conta
            </span>
          </b>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            De maneira a possibilitar a completa funcionalidade do Site, caso seja de interesse do Usuário, ele deverá informar à <b>empbank</b> os seus Dados de Conta e autorizá-la expressamente, na qualidade de mandatária, a acessar seus Dados Financeiros Pessoais disponibilizados no sistema de internet banking das instituições financeiras.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Todas as senhas e/ou os códigos de autorização solicitados pela <b>empbank</b> para o acesso ao sistema de internet banking do Usuário serão utilizados apenas e tão somente nos termos da Política de Privacidade.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> não movimenta ou de qualquer forma interfere nos ativos e contas bancárias informados pelo Usuário. As senhas e/ou códigos de autorização concedidos pelo Usuário se limitam a permitir que a <b>empbank</b> apenas busque Dados Financeiros Pessoais junto a cada instituição financeira informada pelo Usuário, unicamente para fins lícitos relacionados ao propósito a que o Site se destina.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> não se responsabilizará pela revisão ou confirmação dos Dados de Conta fornecidos pelo Usuário, nem pelos Dados Financeiros Pessoais obtidos junto aos sites das instituições financeiras, seja no que tange à precisão dos dados, seja quanto à legalidade ou ameaça de violação em função do fornecimento desses dados.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário está ciente e concorda que a <b>empbank</b> não integra e não pode ser considerada como uma extensão das instituições financeiras das quais os Dados Financeiros Pessoais são extraídos pelo Software, não sendo a <b>empbank</b> em hipótese alguma responsável pelos produtos e/ou serviços oferecidos por estas, seja de maneira solidária ou subsidiária.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O acesso aos Dados de Conta e aos Dados Financeiros Pessoais e Crédito do Usuário depende de serviços prestados por suas respectivas instituições financeiras. Sendo assim, o Usuário está ciente e concorda que a <b>empbank</b> não assume qualquer responsabilidade quanto à pontualidade, precisão, não entrega ou falha na obtenção dos Dados Financeiros Pessoais por meio dos sites destas instituições, já que esses dados poderão estar sujeitos a problemas técnicos ou outras dificuldades de conexão que podem resultar em falha na obtenção, perda de dados ou quaisquer outras interrupções de serviços.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <b>
            <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
              Limitações de Uso e Interferência
            </span>
          </b>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário não poderá:
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Utilizar o Site para divulgar informações que de qualquer forma violem normas aplicáveis no Brasil, direitos de propriedade da <b>empbank</b> e/ou de terceiros ou os bons costumes, incluindo, sem limitação, a violação de direitos intelectuais, autorais e de privacidade, ou a produção e divulgação de conteúdo ilegal, imoral, inapropriado ou ofensivo;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Copiar, ceder, sublicenciar, vender, dar em locação ou em garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o Software, assim como seus módulos, partes, manuais ou quaisquer informações relativas ao mesmo;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Empregar softwares, técnicas e/ou artifícios com o intuito de utilizar indevidamente o Site, e/ou o Software para práticas nocivas à <b>empbank</b> ou a terceiros, tais como exploits, spamming, flooding, spoofing, crashing, root kits, etc.;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte, para qualquer fim, o Software ou qualquer Conteúdo do Site sem a autorização expressa da <b>empbank</b>;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Publicar ou transmitir qualquer arquivo que contenha vírus, worms, cavalos de tróia ou qualquer outro programa contaminante ou destrutivo, ou que de outra forma possa interferir no bom funcionamento do Site e do Software;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Utilizar o Software para finalidade diversa daquela para a qual foi disponibilizado pela <b>empbank</b>;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Divulgar, utilizar ou modificar os dados de outros Usuários; e,
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Utilizar o Software, ou permitir seu uso, para benefício de terceiros.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Informações Confidenciais e Política de Privacidade
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Ao cadastrar-se na
            <b>empbank</b> o Usuário concorda com a Política de Privacidade. O Aceite da Política de Privacidade irá autorizar a <b>empbank</b> a usar os dados do Usuário na maneira descrita no documento.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Propriedade Intelectual
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário não adquire, por meio do presente Termo de Uso ou da Política de Privacidade, nenhum direito de propriedade intelectual ou outros direitos exclusivos, incluindo patentes, desenhos, bases de dados, marcas, direitos autorais ou direitos sobre informações confidenciais ou segredos de negócio, sobre ou relacionados ao Software,&nbsp; e/ou ao Site, os quais são de propriedade exclusiva da <b>empbank</b>.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Caso o Usuário venha a desenvolver um novo módulo ou produto que caracterize cópia, de todo ou d parte do dicionário de dados ou do programa, o novo módulo ou produto será considerado como parte integrante do Software, sendo, portanto, de propriedade da <b>empbank</b> e seu uso estará condicionado a estes Termos de Uso.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário poderá realizar o download ou imprimir uma cópia das informações, planilhas e gráficos disponibilizados por meio do Site desde que para uso pessoal e não comercializável. Qualquer utilização deste material e/ou do Conteúdo do Site, no todo ou em parte, que não para uso pessoal do Usuário, nos termos deste Termo de Uso, sem que haja prévio consentimento por escrito da <b>empbank</b>, é expressamente proibida.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Todo e qualquer Conteúdo disponibilizado no Site, tais como, mas não se limitando a, textos, gráficos, imagens, logos, ícones, fotografias, conteúdo editorial, notificações, softwares e qualquer outro material, pertencem exclusivamente à <b>empbank</b>e são protegidos pela lei brasileira no que se refere à propriedade intelectual e aos direitos autorais.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            No que se refere especialmente aos Dados de Conta e aos Dados Financeiros e Crédito Pessoais, o Usuário entende e concorda que a <b>empbank</b> atuará perante os sites das instituições financeiras, gestão de crédito ou recursos humanos, na qualidade de representante do Usuário. O Aceite dos Termos de Uso e da Política de Privacidade implica em uma autorização expressa à <b>empbank</b> para acessar e utilizar os Dados de Conta e os Dados Financeiros e Crédito Pessoais fornecidos por meio destes sites financeiros.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> poderá, a seu exclusivo critério, a qualquer tempo, e sem a necessidade de comunicação prévia ao Usuário:
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Encerrar, modificar ou suspender, total ou parcialmente, o acesso do Usuário ao Site, quando referido acesso ou cadastro violar as condições estabelecidas nestes Termos de Uso e/ou na Política de Privacidade;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Excluir, total ou parcialmente, as informações cadastradas pelo Usuário que não estejam em consonância com as disposições deste Termo de Uso; e
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Acrescentar, excluir ou modificar o Conteúdo oferecido na <b>empbank</b>.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Poderá a <b>empbank</b>, ainda, a seu exclusivo critério, suspender, modificar ou encerrar as atividades do Site, mediante comunicação prévia ao Usuário, salvo nas hipóteses de caso fortuito ou força maior.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A qualquer tempo, mediante comunicação prévia ao Usuário no endereço de e-mail por este indicado em seu cadastro pessoal ou por meio de aviso no próprio Site, a <b>empbank</b> poderá, ainda:
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Definir preços para oferecimento de determinados conteúdos e/ou serviços, ainda que inicialmente tenham sido ofertados de forma gratuita, sendo a utilização dos mesmos, após o referido aviso, considerada como concordância do Usuário com tais preços; e,
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Enviar ao Usuário mensagens de e-mail ou outras correspondências de caráter informativo, comercial e/ou promocional, salvo expressa solicitação em contrário pelo Usuário, nos termos da Política de Privacidade.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Limitação de Responsabilidade
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O USUÁRIO ESTÁ CIENTE DE QUE O SOFTWARE APENAS OBTÉM E GERENCIA, DE FORMA AUTOMATIZADA, OS DADOS FINANCEIROS PESSOAIS E DE GESTÃO DE EMPRÉSTIMOS CONSIGNADOS DO USUÁRIO. O SOFTWARE NÃO REALIZA EMPRÉSTIMOS NEM SE RESPONSABILIZA PELOS PRODUTOS FINANCEIROS DAS INSTITUIÇÕES FINANCEIRAS PARCEIRAS EVENTUALMENTE CONTRATADOS.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> não responderá, em nenhuma hipótese, ainda que em caráter solidário ou subsidiário:
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", marginRight: "0in", marginBottom: ".0001pt", marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Pela autenticidade, validade e precisão dos dados fornecidos pelos Usuários e/ou coletadas nos sites das instituições financeiras, conforme previsto no Item 5 acima, competindo ao Usuário verificar, por conta própria, se tais informações são verdadeiras, completas e atualizadas antes de tomar qualquer decisão nelas baseada;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Pelos serviços ou produtos oferecidos no Site, por anunciantes ou quaisquer terceiros, inclusive no que diz respeito a sua disponibilidade, qualidade, quantidade, características essenciais, ofertas, preços, formas de pagamento ou quaisquer outros elementos a ele referentes;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Por eventuais prejuízos sofridos pelos Usuários ou por terceiros em razão da tomada de decisões com base nas informações disponibilizadas no Site;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Por eventuais prejuízos sofridos pelos Usuários em razão de falhas no sistema de informática ou nos servidores que independam de culpa da <b>empbank</b> ou em sua conectividade com a internet de modo geral, devendo o Usuário manter, às suas expensas, linha de telecomunicação, modem, software de comunicação, endereço de correio eletrônico e outros recursos necessários à comunicação com a <b>empbank</b>;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Por situações de caso fortuito ou força maior, nos termos do artigo 393 do Código Civil Brasileiro;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginLeft: ".5in", textAlign: "justify", textIndent: "-.25in" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            ●<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Por danos causados por programas nocivos ao Software, tais como, mas sem se limitar a vírus, trojans e hackers;
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> não garante que as funções contidas no Software atendam às necessidades do Usuário, que a operação do Software será ininterrupta ou livre de erros, que qualquer funcionalidade continuará disponível, que os defeitos no Software serão corrigidos ou que o Software será compatível ou funcionará com qualquer software, aplicações ou serviços de terceiros.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> fará todo o possível para manter os dados sempre seguros, inclusive, irá adotar medidas de segurança e de proteção compatíveis com a natureza dos dados coletados, usados e armazenados, no entanto, a <b>empbank</b> não garante de forma alguma que tais medidas de segurança sejam isentas de erros ou que não estejam sujeitas a interferência de terceiros (hackers entre outros). Por sua natureza, apesar dos melhores esforços da <b>empbank</b>, qualquer medida de segurança pode falhar e qualquer dado do Usuário fornecido ao ou coletado pela <b>empbank</b>
            pode se tornar público. AO ACEITAR O TERMO DE USO E/OU USAR A <b>empbank</b> O USUÁRIO ENTENDE E ASSUME EXPRESSAMENTE ESSE RISCO E CONCORDA QUE A <b>empbank</b> NÃO SERÁ RESPONSÁVEL POR TAL TIPO DE VAZAMENTO DE DADOS.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> NÃO SE RESPONSABILIZA POR QUALQUER DECISÃO FINANCEIRA TOMADA PELO USUÁRIO COM BASE NAS INFORMAÇÕES OBTIDAS POR MEIO DA UTILIZAÇÃO DO SOFTWARE, incluindo, sem limitação, qualquer decisão tomada utilizando-se de eventual informação incorreta/desatualizada disponibilizada pela <b>empbank</b>. Será responsabilidade exclusiva do Usuário conferir qualquer informação disponibilizada pela <b>empbank</b>, junto à instituição bancária de origem dos respectivos Dados Financeiros Pessoais.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Indenização
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário concorda em defender, indenizar e manter indene a <b>empbank</b> e suas afiliadas, diretores, empregados e agentes, de e contra quaisquer encargos, ações ou demandas, incluindo, mas não limitado a honorários advocatícios razoáveis, resultantes: (i) da sua eventual utilização indevida do Site, do Software e/ou do seu Conteúdo; ou (ii) da violação das condições ora pactuadas.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Em nenhum caso a <b>empbank</b> será responsável por danos pessoais ou qualquer prejuízo incidental, indireto ou consequente, lucros cessantes, incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, não continuidade do negócio ou qualquer outro prejuízo ou perda comercial incorridos pelos Usuários ou por terceiros, decorrentes ou relacionados ao uso ou sua inabilidade em usar o Software ou os serviços da <b>empbank</b>, por qualquer motivo.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Na eventualidade da <b>empbank</b> ser compelida, por decisão judicial, a indenizar ou ressarcir o Usuário ou terceiros por danos sofridos, o valor devido será limitado à totalidade da quantia efetivamente paga pelo Usuário à <b>empbank</b> a título de fruição das funcionalidades oferecidas pelo Site.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Vigência e Rescisão
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Termo de Uso e a Política de Privacidade vigerão por prazo indeterminado, a partir do Aceite do Usuário, podendo ser modificados ou rescindidos unilateralmente pela <b>empbank</b> a qualquer tempo, sem qualquer ônus, mediante simples comunicação por meio do próprio Site ou de mensagem para o e-mail indicado no cadastro pessoal do Usuário.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário poderá, a qualquer momento, solicitar a exclusão da sua Conta <b>empbank</b>, encerrando seu relacionamento com a <b>empbank</b>, mediante solicitação realizada diretamente por meio do Site. Neste caso, os dados do Usuário serão tratados da forma prevista na Política de Privacidade.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Modificações
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            A <b>empbank</b> poderá, a qualquer tempo, alterar este Termo de Uso ou a Política de Privacidade, ao seu exclusivo critério. Quaisquer alterações nestes Termos de Uso e na Política de Privacidade serão informadas por meio do Site. Caso o Usuário continue utilizando os serviços disponibilizados pelo Site, a <b>empbank</b> considerará que o Usuário concorda com as alterações.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Será sempre indicada a data da última atualização realizada pela <b>empbank</b> ao presente Termo de Uso.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Usuário entende e concorda que, assim que publicada a alteração deste Termo de Uso/Política de Privacidade no Site, o uso do Site sujeitar-se-á ao Termo de Uso/Política de Privacidade atualizados.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Disposições Gerais
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Este Termo de Uso não gera nenhum vínculo de sociedade, franquia ou relação de trabalho entre a <b>empbank</b> e o Usuário, seus parceiros e/ou anunciantes.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Caso qualquer disposição deste Termo de Uso ou da Política de Privacidade seja considerada ilegal, nula ou inexequível por qualquer razão, as disposições restantes não serão afetadas e manter-se-ão válidas e aplicáveis na máxima extensão possível.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            O Termo de Uso e a Política de Privacidade constituem a totalidade do acordo sobre as condições de uso do Site e do Software. O Usuário declara ter ciência dos direitos e obrigações decorrentes do presente Termo de Uso e a Política de Privacidade, tendo lido, compreendido e aceito todos os termos e condições.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Qualquer falha da <b>empbank</b> para impor ou exercer qualquer disposição deste Termo de Uso, da Política de Privacidade ou direitos conexos, não constitui uma renúncia a esse direito ou disposição.
          </span>
        </p>
        <h2 style={{ marginBottom: "0in", textAlign: "justify", pageBreakAfter: "auto" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Lei e Foro Aplicáveis
          </span>
        </h2>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Este Termo de Uso será interpretado exclusivamente segundo as leis do Brasil.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            As partes elegem o Foro da Comarca de Brasília, Distrito Federal, como o único competente para dirimir qualquer litígio resultante deste Termo de Uso ou da Política de Privacidade.
          </span>
        </p>
        <p className="MsoNormal" style={{ marginTop: "12.0pt", textAlign: "justify" }}>
          <span lang="EN" style={{ fontSize: "12.0pt", lineHeight: "115%", fontFamily: "Roboto" }}>
            Última atualização: 25, de Maio de 2021
          </span>
        </p>
        <p className="MsoNormal">
          <span lang="EN">&nbsp;</span>
        </p>
      </div>
    </div>
  )
}

export default TermsOfUseContent
