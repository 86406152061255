import React from "react"
import { Redirect } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

// components
import Hello from "./Hello"
import Products from "./Products"
import Testimonials from "./Testimonials"

import styles from "./PartnerArea.module.scss"

const PartnerArea = () => {
  const { isAuthenticated, user } = useAuth0()

  !isAuthenticated && <Redirect to="/parceria" />

  return (
    <div className={styles.container}>
      <Hello user={user} />
      <Products />
      <Testimonials />
    </div>
  )
}

export default PartnerArea
