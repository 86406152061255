import React from "react"

// image
import LogoStamp from "../../assets/logos/logoStamp.svg"

import styles from "./ThirdStep.module.scss"

const ThirdStep = () => {
  return (
    <div className={styles.container}>
      <img src={LogoStamp} alt="Selo Empbank" className={styles.image} />

      <h1 className={styles.title}>
        Você está a <span className={styles.titleHighlightSecondary}>um passo</span> de tornar-se um parceiro <span className={styles.titleHighlightPrimary}>Empbank</span>.
      </h1>

      <div className={styles.contractBox}>
        <div className={styles.titleContainer}>
          <h2 className={styles.innerTitle}>Solicitação de Parceria</h2>

          <p className={styles.description}>Preencha os campos abaixo para solicitar Cadastro de Parceria.</p>
        </div>

        <div className={styles.contractWrapper}>
          <div className={styles.contractContainer}>
            <iframe title="Cadastro de Parceiro" frameborder="0" scrolling="no" src="https://app.ummense.com/form/1ebaa029-257d-6056-8b11-0a7cd410799c?iframe=1" width="100%" height="100%"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThirdStep
