import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import "./App.scss"
import { useAuth0 } from "@auth0/auth0-react"

// components

import Navbar from "./components/organisms/Navbar/Navbar"
import Footer from "./components/organisms/Footer/Footer"
import LandingPage from "./pages/LandingPage/LandingPage"
import Partner from "./pages/Partner/Partner"
import About from "./pages/About/About"
import Culture from "./pages/Culture/Culture"
import PartnerArea from "./pages/PartnerArea/PartnerArea"
import B2C from "./pages/B2C/B2C"
import FirstStep from "./pages/B2B/FirstStep"
import SecondStep from "./pages/B2B/SecondStep"
import ThirdStep from "./pages/B2B/ThirdStep"
import TermsOfUse from "./pages/TermsOfUse/TermsOfUse"
import Contact from "./pages/Contact/Contact"
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy"
import ScrollToTop from "./utils/ScrollToTop"

function App() {
  const { isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0()

  if (isLoading) return <div>Carregando...</div>
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar isAuthenticated={isAuthenticated} logout={logout} loginWithRedirect={loginWithRedirect} />
        <Switch>
          <Route path="/sobre">
            <About />
          </Route>
          <Route path="/cotação">
            <B2C />
          </Route>
          <Route path="/cultura">
            <Culture />
          </Route>
          <Route path="/contato">
            <Contact />
          </Route>
          <Route exact path="/parceria">
            <Partner />
          </Route>
          <Route path="/parceria/1-de-3">
            <FirstStep />
          </Route>
          <Route path="/parceria/2-de-3">
            <SecondStep />
          </Route>
          <Route path="/parceria/3-de-3">
            <ThirdStep />
          </Route>
          <Route path="/termos-de-uso">
            <TermsOfUse />
          </Route>
          <Route path="/política-de-privacidade">
            <PrivacyPolicy />
          </Route>
          <Route path="/area-do-parceiro">{!isAuthenticated ? <Redirect to="/parceria" /> : <PartnerArea />}</Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  )
}

export default App
