import React, { useState } from "react"
import { Link } from "react-router-dom"

import { Drawer } from "antd"

// components
import Button from "../../atoms/Button/Button"

import styles from "./Navbar.module.scss"

const DrawerMenu = ({ navlinks, dynamicStyles, isAuthenticated, logout, loginWithRedirect }) => {
	const [visible, setVisible] = useState(false)
	const showDrawer = () => {
		setVisible(true)
	}
	const onClose = () => {
		setVisible(false)
	}

	const handleLogout = () => {
		onClose()
		logout()
	}
	const handleLogin = () => {
		onClose()
		// loginWithRedirect({
		//   redirectUri: "https://eager-varahamihira-f77f74.netlify.app/area-do-parceiro/"
		// })
		loginWithRedirect()
	}

	return (
		<div>
			<Button variant="loginSecondary" onClick={showDrawer} style={dynamicStyles}>
				MENU
			</Button>
			<div className={styles.drawerContainer}>
				<Drawer placement="right" width="300px" closable={false} onClose={onClose} visible={visible} drawerStyle={{ backgroundColor: "#000000", padding: 0, maxHeight: "100vh" }}>
					<Button variant="loginSecondary" onClick={onClose} style={{ color: "#cecece", borderColor: "#cecece" }}>
						FECHAR
					</Button>

					<div className={styles.linksMobileContainer}>
						{navlinks.map(navlink => {
							return (
								<div key={navlink.title} className={styles.linksMobile}>
									{navlink.type === "route" && (
										<li>
											<Link to={`/${navlink.title}`} onClick={onClose}>
												{navlink.title.toUpperCase()}
											</Link>
										</li>
									)}
									{navlink.type === "url" && (
										<li>
											<a href="https://medium.com/@cadastro_78770" target="_blank" rel="noreferrer" onClick={onClose}>
												{navlink.title.toUpperCase()}
											</a>
										</li>
									)}
								</div>
							)
						})}
						<div>
							{!isAuthenticated ? (
								<>
									<div className={styles.linksMobile}>
										<li onClick={handleLogin}>ENTRAR</li>
									</div>
									<div className={styles.linksMobile}>
										<li>
											<Link to="/parceria" onClick={onClose}>
												CRIAR CONTA
											</Link>
										</li>
									</div>
								</>
							) : (
								<>
									<div className={styles.linksMobile}>
										<li onClick={handleLogout}>SAIR</li>
									</div>

									<div className={styles.linksMobile}>
										<li>
											<Link to="/area-do-parceiro" onClick={onClose}>
												ÁREA DO PARCEIRO
											</Link>
										</li>
									</div>
								</>
							)}
						</div>
					</div>
				</Drawer>
			</div>
		</div>
	)
}

export default DrawerMenu
