import React from "react"
import { Link } from "react-router-dom"

// components
import Button from "../atoms/Button/Button"

const CreateAccountButton = ({ style }) => {
  return (
    <Button variant="loginPrimary" style={style}>
      <Link to="/parceria">CRIAR CONTA</Link>
    </Button>
  )
}

export default CreateAccountButton
