import React from "react"

// components
import PrivacyPolicyContent from "./PrivacyPolicyContent"

import styles from "./PrivacyPolicy.module.scss"

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <PrivacyPolicyContent />
    </div>
  )
}

export default PrivacyPolicy
