import React, { useState, useEffect } from "react"
import { Link, useLocation } from "react-router-dom"

// logo
import LogoPrimary from "../../../assets/logos/logoPrimary.svg"
import LogoSecondary from "../../../assets/logos/logoSecondary.svg"

// components
import LoginButton from "../../molecules/LoginButton"
import LogoutButton from "../../molecules/LogoutButton"
import CreateAccountButton from "../../molecules/CreateAccountButton"
import PartnerAreaButton from "../../molecules/PartnerAreaButton"
import MediaQueries from "../../../utils/MediaQueries"
import DrawerMenu from "./DrawerMenu"

import styles from "./Navbar.module.scss"

const Navbar = ({ isAuthenticated, logout, loginWithRedirect }) => {
  const navlinks = [
    {
      type: "route",
      title: "sobre"
    },
    {
      type: "route",
      title: "cotação"
    },
    {
      type: "route",
      title: "cultura"
    },
    {
      type: "url",
      title: "blog"
    },
    {
      type: "route",
      title: "contato"
    }
  ]

  const [scroll, setScroll] = useState(0)

  const handleScroll = () => {
    setScroll(window.scrollY)
  }

  useEffect(() => {
    setInterval(function () {
      window.addEventListener("scroll", handleScroll)
    }, 3000)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const location = useLocation()

  const dynamicStyles = scroll < 50 ? { backgroundColor: location.pathname === "/" ? "#EAEAEA" : "#F1F1F1" } : { backgroundColor: "#57585A", color: "white", borderColor: "white" }
  const dynamicButtonStyle = scroll > 50 ? { backgroundColor: "white", color: "#57585A", borderColor: "#57585A" } : null
  const logo = scroll < 50 ? LogoPrimary : LogoSecondary

  const navlinkRouteClasses = navlink => (location.pathname === `/${navlink.title}` ? `${styles.navlinkRoute} ${styles.navlinkActive}` : `${styles.navlinkRoute}`)

  const { isTabletOrMobile, isLessThan1300px } = MediaQueries()

  return (
    <div className={styles.container} style={{ position: "fixed", zIndex: "2", ...dynamicStyles }}>
      <div className={styles.logo}>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="Logo da Empbank" />
        </Link>
      </div>

      <ul className={styles.navlinksList}>
        {navlinks.map(navlink => {
          return (
            <div className={styles.navlinkContainer} key={navlink.title}>
              {navlink.type === "route" && (
                <li className={navlinkRouteClasses(navlink)}>
                  <Link to={`/${navlink.title}`}>{navlink.title.toUpperCase()}</Link>
                </li>
              )}
              {navlink.type === "url" && (
                <li className={styles.navlinkLink}>
                  <a href="https://medium.com/@cadastro_78770" target="_blank" rel="noreferrer">
                    {navlink.title.toUpperCase()}
                  </a>
                </li>
              )}
            </div>
          )
        })}
      </ul>

      {isTabletOrMobile ? (
        <DrawerMenu navlinks={navlinks} dynamicStyles={dynamicStyles} isAuthenticated={isAuthenticated} logout={logout} loginWithRedirect={loginWithRedirect} />
      ) : (
        <div className={styles.btnContainer}>
          {!isAuthenticated ? (
            <div className={styles.loggedOut}>
              <LoginButton style={dynamicStyles} text="ENTRAR" />
              {!isLessThan1300px && <CreateAccountButton style={dynamicButtonStyle} />}
            </div>
          ) : (
            <div className={styles.loggedIn}>
              <LogoutButton style={dynamicStyles}>SAIR</LogoutButton>
              {!isLessThan1300px && <PartnerAreaButton style={dynamicButtonStyle}>ÁREA DO PARCEIRO</PartnerAreaButton>}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Navbar
