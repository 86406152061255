import React from "react"
import MediaQueries from "../../utils/MediaQueries"

import styles from "./Hello.module.scss"

const Hello = ({ user }) => {
  const { isTabletOrMobile } = MediaQueries()

  return (
    <div className={styles.container}>
      <div className={styles.greetings}>
        <h1>
          Olá<strong>{user && `, ${user.name ? user.name : user.email ? user.email : null}`}</strong>!
        </h1>
        <h3>
          Como podemos ajudar o seu <strong>negócio</strong> hoje?
        </h3>
      </div>
      <div className={styles.services}>
        <div className={styles.upperGridContainer}>
          <div className={`${styles.gridItem1} ${styles.gridItem} ${styles.upperGridItem}`}>
            <a href="https://wa.me/5543984236803" target="_blank" rel="noreferrer">
              <div className={styles.upperBorder}>EMPRÉSTIMO CONSIGNADO</div>
            </a>
          </div>
          <div className={`${styles.gridItem2} ${styles.gridItem} ${styles.upperGridItem}`}>
            <a href="https://wa.me/5543984236803" target="_blank" rel="noreferrer">
              <div className={styles.upperBorder}>EMPRÉSTIMO PESSOAL</div>
            </a>
          </div>
          {isTabletOrMobile && (
            <>
              <div className={`${styles.gridItem2} ${styles.gridItem6} ${styles.gridItem} ${styles.upperGridItem}`}>
                <a href="https://wa.me/5543984236803" target="_blank" rel="noreferrer">
                  <div className={styles.upperBorder}>REFINANCIAMENTO</div>
                </a>
              </div>
              <div className={`${styles.gridItem2} ${styles.gridItem} ${styles.upperGridItem}`}>
                <a href="https://wa.me/5543984236803" target="_blank" rel="noreferrer">
                  <div className={styles.upperBorder}>PORTABILIDADE DE DÍVIDA</div>
                </a>
              </div>
              <div className={`${styles.gridItem2} ${styles.gridItem} ${styles.upperGridItem}`}>
                <a href="https://wa.me/5543984236803" target="_blank" rel="noreferrer">
                  <div className={styles.upperBorder}>SAQUE FGTS</div>
                </a>
              </div>
            </>
          )}
        </div>

        {!isTabletOrMobile && (
          <div className={styles.lowerGridContainer}>
            <div className={`${styles.gridItem3} ${styles.gridItem} ${styles.lowerGridItem}`}>
              <a href="https://wa.me/5543984236803" target="_blank" rel="noreferrer">
                <div className={styles.lowerBorder}>REFINANCIAMENTO</div>
              </a>
            </div>

            <div className={`${styles.gridItem4} ${styles.gridItem} ${styles.lowerGridItem}`}>
              <a href="https://wa.me/5543984236803" target="_blank" rel="noreferrer">
                <div className={styles.lowerBorder}>PORTABILIDADE DE DÍVIDA</div>
              </a>
            </div>
            <div className={`${styles.gridItem5} ${styles.gridItem} ${styles.lowerGridItem}`}>
              <a href="https://wa.me/5543984236803" target="_blank" rel="noreferrer">
                <div className={styles.lowerBorder}>SAQUE FGTS</div>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Hello
