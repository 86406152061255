import React from "react"

// components
import Button from "../atoms/Button/Button"

const PartnerProductButton = ({ children, className, style, onClick }) => {
  return (
    <Button variant="partnerProduct" className={className} style={style} onClick={onClick}>
      {children}
    </Button>
  )
}

export default PartnerProductButton
