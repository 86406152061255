import React from "react"

// components
import Button from "../atoms/Button/Button"

const BuyButton = ({ style, className, disabled, onClick, loading }) => {
  return (
    <Button variant="buyBtn" onClick={onClick} disabled={disabled} style={style} className={className}>
      {loading ? "Carregando..." : "Comprar"}
    </Button>
  )
}

export default BuyButton
