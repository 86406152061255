import React from "react"

// components
import Button from "../atoms/Button/Button"

const PartnerButton = ({ children, style, onClick, disabled }) => {
  return (
    <Button variant="callPrimary" style={style} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  )
}

export default PartnerButton
