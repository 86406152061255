import React from "react"
import { Link } from "react-router-dom"

import HeroImage from "../../assets/images/hero-image.png"
import HeroImageMobile from "../../assets/images/hero-image-mobile.png"

// components
import ReadMoreButton from "../../components/molecules/ReadMoreButton"
import PartnerButton from "../../components/molecules/PartnerButton"
import MediaQueries from "../../utils/MediaQueries"

import styles from "./Hero.module.scss"

const Hero = () => {
  const { isTabletOrMobile, isMobile } = MediaQueries()

  const readMoreButtonStyle = isMobile ? { marginBottom: "10px" } : { marginRight: "27px" }

  return (
    <div className={styles.container} style={{ backgroundImage: !isTabletOrMobile ? `url(${HeroImage})` : `url(${HeroImageMobile})` }}>
      <div className={styles.content}>
        <h1 className={styles.slogan}>
          <span className={styles.sloganTitle}>Empbank. </span>
          <span>Seu parceiro financeiro </span>
          <span className={styles.sloganHighlight}>fácil </span>e <span className={styles.sloganHighlight}>rápido</span>.
        </h1>
        <h5 className={styles.subslogan}>
          <span>Crédito fácil para seu cliente.</span>
          <span>Vendas rápidas para sua empresa.</span>
        </h5>
        <div className={styles.callButtons}>
          <ReadMoreButton style={readMoreButtonStyle} />
          <PartnerButton>
            <Link to="/parceria">QUERO SER PARCEIRO</Link>
          </PartnerButton>
        </div>
      </div>
    </div>
  )
}

export default Hero
