import React from "react"

// icons
import OpenQuotes from "../../assets/icons/OpenQuotes.svg"
import CloseQuotes from "../../assets/icons/CloseQuotes.svg"

import styles from "./Testimonials.module.scss"

const Testimonials = () => {
  const testimonials = [
    {
      client: "OdontoCerta",
      text: "Graças a Empbank, não perdemos mais tempo em filas de bancos, em agências ou telefone. Atendimento online e em poucas horas temos uma nova venda."
    },
    {
      client: "Clínica Silva",
      text: "Serviço ágil, atendimento personalizado. Diminuiu nosso tempo de espera para avaliação de crédito em 90% e, por isso, aumentou nossas vendas."
    },
    {
      client: "ORT",
      text: "Nossa equipe financeira não tem mais reclamações dos fornecedores. Desde o primeiro contato, não perdemos uma só venda por falta de serviços financeiros aos nossos clientes."
    }
  ]

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        O que os outros <strong>parceiros</strong> falam dos nossos produtos e serviços
      </h1>
      <div className={styles.testimonials}>
        {testimonials.map(testimonial => {
          return (
            <div className={styles.testimonial} key={testimonial.client}>
              <h2 className={styles.client}>{testimonial.client}</h2>
              <div className={styles.quoteContainer}>
                <div className={styles.openQuotes}>
                  <img src={OpenQuotes} alt="Abre aspas" />
                </div>
                <p className={styles.text}>{testimonial.text}</p>
                <div className={styles.closeQuotes}>
                  <img src={CloseQuotes} alt="Fecha aspas" />
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Testimonials
