import React from "react"
import { Link } from "react-router-dom"

// images
import LogoSecondary from "../../../assets/logos/logoSecondary.svg"

// icons
import FacebookLogo from "../../../assets/icons/Facebook.svg"
import InstagramLogo from "../../../assets/icons/Instagram.svg"
import LinkedinLogo from "../../../assets/icons/LinkedinLogo.svg"
import WhatsAppLogo from "../../../assets/icons/WhatsApp.svg"

import CertificateOne from "../../../assets/icons/CertificateOne.svg"
import CertificateTwo from "../../../assets/icons/CertificateTwo.svg"

import styles from "./Footer.module.scss"

const Footer = () => {
  const socialMedias = [
    {
      icon: FacebookLogo,
      link: "https://www.facebook.com/Empbank.com.br/"
    },
    {
      icon: InstagramLogo,
      link: "https://www.instagram.com/empbank/"
    },
    {
      icon: LinkedinLogo,
      link: "https://www.linkedin.com/company/empbnak"
    },
    {
      icon: WhatsAppLogo,
      link: "https://wa.me/5543984236803/"
    }
  ]

  const footerContent = [
    {
      title: "PARA VOCÊ",
      link: "/cotação",
      items: ["Empréstimo Pessoal", "Empréstimo Consignado", "Refinanciamento", "Portabilidade de Dívida", "Saque FGTS"]
    },
    {
      title: "",
      link: "",
      items: [],
      id: "gridEmpty1"
    },
    {
      title: "PARA SUA EMPRESA",
      link: "/parceria",
      items: ["Benefícios", "Serviços", "Seja nosso Parceiro"]
    },
    {
      title: "",
      link: "",
      items: [],
      id: "gridEmpty2"
    },
    {
      title: "BANCOS PARCEIROS",
      items: ["Banrisul", "Banco BMG", "Bradesco", "Celetem", "Banco Daycoval", "Itaú", "Mercantil do Brasil", "Olé Consignado", "Banco Pan"]
    },
    {
      title: "",
      link: "",
      items: [],
      id: "gridEmpty3"
    },
    {
      title: "CONTATO",
      link: "/contato",
      items: ["Dúvidas", "Sugestões", "WhatsApp", "WhatsApp Empresas", "Telefone"]
    }
  ]

  return (
    <div className={styles.container}>
      <div className={styles.upperContainer}>
        <div className={styles.logoContainer}>
          <img src={LogoSecondary} alt="Logo da Empbank" className={styles.logo} />
          <p className={styles.slogan}>Seu parceiro financeiro fácil e rápido.</p>
          <div className={styles.socialMediaContainer}>
            {socialMedias.map(socialMedia => {
              return (
                <a href={socialMedia.link} key={socialMedia.link} target="_blank" rel="noreferrer">
                  <div>
                    <img src={socialMedia.icon} alt={socialMedia.icon} />
                  </div>
                </a>
              )
            })}
          </div>
          <div className={styles.certificateContainer}>
            <a href="https://aneps.org.br/" target="_blank" rel="noreferrer">
              <img src={CertificateTwo} alt="Certificado" />
            </a>

            <a href="https://fi.co/" target="_blank" rel="noreferrer">
              <img src={CertificateOne} alt="Certificado" />
            </a>
          </div>
        </div>

        <div className={styles.grid}></div>

        {footerContent.map(list => {
          return list.title === "" ? (
            <div key={list.id}></div>
          ) : (
            <div key={list.title}>
              <div className={styles.listContainer}>
                <h5 className={styles.listTitle}>{list.title}</h5>
                <ul className={styles.itemList}>
                  {list.items.map(item => {
                    return list.link ? (
                      <Link to={list.link} key={item}>
                        <li>{item}</li>
                      </Link>
                    ) : (
                      <li key={item}>{item}</li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )
        })}
      </div>

      <div className={styles.midContainer}>
        <p>Fique atento! A EMPBANK não solicita nenhum tipo de pagamento antecipado para empréstimos. Se tiver dúvidas, entre em contato!</p>
        <p>empbank.com.br pertence à Empbank, sociedade empresária inscrita no CNPJ 27.911.069/0001-23, com sede na Av. Higienópolis, 1601 – Térreo – Conj. 15 – Sala Paris – Centro – Londrina – PR – CEP 86020-080. A EMPBANK não é uma instituição financeira. Nós atuamos como correspondente bancário (nos termos da Resolução 3.594/11 do Banco Central do Brasil) e, nesse sentido, intermediamos o processo de contratação de empréstimos pessoais junto às instituições financeiras parceiras.</p>
      </div>

      <div className={styles.lowerContainer}>
        <p>© 2022 Empbank. Todos os direitos reservados.</p>
        <p>
          <Link to="/termos-de-uso">Termos de Uso </Link>e<Link to="/política-de-privacidade"> Política de Privacidade</Link>
        </p>
      </div>
    </div>
  )
}

export default Footer
