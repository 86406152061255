import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import axios from "axios"
import { Form } from "react-bootstrap"
import MediaQueries from "../../utils/MediaQueries"

// components
import DynamicForm from "./DynamicForm"
import Button from "../../components/atoms/Button/Button"

import styles from "./B2C.module.scss"

const B2C = () => {
	const [form, setForm] = useState()
	const [formComplete, setFormComplete] = useState(false)
	const [checkboxActive, setChecboxActive] = useState(false)
	const [loading, setLoading] = useState(false)
	const [formSent, setFormSent] = useState(false)

	const { isMobile, isBigScreen } = MediaQueries()

	useEffect(() => {
		if (form) {
			if (form?.career && form?.cpf && form?.name && form?.birthDate && form?.email && form?.phone) {
				setFormComplete(true)
			} else {
				setFormComplete(false)
			}
		}
	}, [form])

	const handleFormSubmit = e => {
		const service_id = "service_50ydn3e"
		const template_id = "template_m4k3ime"
		const user_id = "user_RV5M2mhLXPY2qa7m9XnBp"
		setLoading(true)
		axios
			.post("https://api.emailjs.com/api/v1.0/email/send", {
				service_id,
				template_id,
				user_id,
				template_params: {
					career: form.career,
					regime: form.regime,
					sphere: form.sphere,
					hiringYear: form.hiringYear,
					affiliation: form.affiliation,
					organization: form.organization,
					state: form.state,
					cpf: form.cpf,
					name: form.name,
					birthDate: form.birthDate,
					email: form.email,
					phone: form.phone,
				},
			})
			.then(function (response) {
				console.log(response)
				setForm(null)
				setLoading(false)
				isMobile &&
					window.scroll(0, 300, {
						behavior: "smooth",
					})
				!isMobile &&
					window.scroll(0, 0, {
						behavior: "smooth",
					})
				setFormSent(true)
			})
			.catch(function (error) {
				console.log(error)
				setLoading(false)
				setForm(null)
			})
	}

	const handleNewRequest = () => {
		window.scroll(0, 0, {
			behavior: "smooth",
		})
		setForm(null)
		setFormSent(false)
	}

	return (
		<div className={styles.container}>
			<div className={styles.leftContainer}>
				<div>
					<h1 className={styles.slogan}>Faça uma cotação e compare</h1>
					<p className={styles.description}>Complete seus dados e clique em Solicitar Orçamento. Nossa equipe receberá seu pedido e enviará uma cotação ao seu E-mail e/ou WhatsApp em seguida.</p>
				</div>
			</div>
			<div className={styles.rightContainer}>
				{formSent ? (
					<div className={styles.formContainer} style={{ minHeight: "auto" }}>
						<h1>Obrigado pelo contato. Retornaremos o mais breve possível.</h1>
						<Button variant="loginPrimary" onClick={handleNewRequest} className="w-100 mt-5">
							Fazer mais uma cotação
						</Button>
					</div>
				) : (
					<div className={styles.formContainer} style={{ maxWidth: isBigScreen && "800px" }}>
						<h1 className={styles.formTitle}>Orçamento</h1>

						<DynamicForm form={form} setForm={setForm} handleFormSubmit={handleFormSubmit} id="myform" />
						<div className={styles.checkboxContainer}>
							<Form.Check type="checkbox" checked={checkboxActive} onChange={() => setChecboxActive(!checkboxActive)} />
							<span>
								Ao solicitar orçamento, você aceita os nossos <Link to="/termos-de-uso">Termos de Uso</Link> e <Link to="/política-de-privacidade">Política de Privacidade</Link>
							</span>
						</div>
						<Button variant="buyBtn" form="myform" type="submit" disabled={!formComplete || !checkboxActive} style={{ width: "100%" }}>
							{!loading ? "Solicitar Orçamento" : "Carregando..."}
						</Button>
					</div>
				)}
			</div>
		</div>
	)
}

export default B2C


