import React, { useState, useEffect } from "react"
import { Form, Select, Input } from "antd"

const { Option } = Select

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)
}

const brazilianStates = ["do Acre", "do Amapá", "do Amazonas", "do Pará", "de Rondônia", "de Roraima", "de Tocantins", "do Maranhão", "do Piauí", "do Ceará", "do Rio Grande do Norte", "de Paraíba", "de Pernambuco", "do Alagoas", "de Sergipe", "da Bahia", "do Mato Grosso", "do Mato Grosso do Sul", "de Goiás", "do Distrito Federal", "de Minas Gerais", "do Espírito Santo", "de São Paulo", "do Rio de Janeiro", "do Paraná", "de Santa Catarina", "do Rio Grande do Sul"]

const brazilianStatesClean = brazilianStates.map(state => {
  return state.slice(3, state.length)
})

const featuredCities = ["Belo Horizonte/MG", "Cuiabá/MT", "Fortaleza/CE", "Maceió/AL", "Recife/PE", "São Luís/MA", "São Paulo/SP", "Outros"]

const featuredOrganizations = ["CBMDF", "PCDF", "PMDF", "SESDF", "SEEDF", "Outro"]

const data = [
  { career: "Servidor Federal", regime: ["Estatuário", "Comissionado", "CLT"], hiringYear: range(1930, 2021), sphere: ["Executivo", "Legislativo", "Judiciário"] },
  {
    career: "Servidor Estadual",
    regime: ["Estatuário", "Comissionado", "CLT"],
    hiringYear: range(1930, 2021),
    affiliation: brazilianStates.map(state => {
      return `Governo do Estado ${state}`
    })
  },
  { career: "Servidor Municipal", affiliation: featuredCities },
  { career: "Servidor GDF", organization: featuredOrganizations },
  { career: "Forças Armadas", regime: ["Carreira", "Temporário"], affiliation: ["Exército", "Marinha", "Aeronáutica"] },
  { career: "Pensionista", state: brazilianStatesClean, affiliation: ["INSS", "SIAPE"] },
  { career: "Aposentado", state: brazilianStatesClean, affiliation: ["INSS", "SIAPE"] },
  { career: "Empresário" },
  { career: "Autônomo" },
  { career: "Estagiário" },
  { career: "Funcionário de empresa privada" }
]

const DynamicForm = ({ form, setForm, handleFormSubmit, id }) => {
  const [selectedCareer, setSelectedCareer] = useState(null)
  const careerState = useState([])
  const setCareer = careerState[1]

  const [regime, setRegime] = useState(null)
  const [hiringYear, setHiringYear] = useState(null)
  const [sphere, setSphere] = useState(null)
  const [affiliation, setAffiliation] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [state, setState] = useState(null)

  const handleCareerChange = value => {
    const _selectedCareer = data.find(career => {
      return career.career === value
    })
    setRegime(null)
    setSphere(null)
    setHiringYear(null)
    setAffiliation(null)
    setOrganization(null)
    setState(null)
    setSelectedCareer(_selectedCareer)
    setForm({
      career: value
    })
  }

  useEffect(() => {
    if (selectedCareer) {
      setCareer(selectedCareer.career)
      selectedCareer.regime && setRegime(selectedCareer.regime)
      selectedCareer.sphere && setSphere(selectedCareer.sphere)
      selectedCareer.affiliation && setAffiliation(selectedCareer.affiliation)
      selectedCareer.organization && setOrganization(selectedCareer.organization)
      selectedCareer.state && setState(selectedCareer.state)
    }
  }, [selectedCareer, setCareer])

  const handleRegimeChange = value => {
    setForm({
      career: form.career,
      regime: value
    })
    if (value === "CLT") {
      selectedCareer.hiringYear && setHiringYear(selectedCareer.hiringYear)
    }
  }

  const labelStyle = { fontWeight: 700, fontFamily: "Lato, sans-serif", fontSize: 16 }

  const selectStyle = { fontWeight: 400, color: "#ABB5BE", border: "1px solid #ABB5BE", borderRadius: "8px", padding: 0, fontSize: 16, marginTop: 0 }

  const inputStyle = { ...selectStyle, padding: "5px 10px" }

  const label = text => {
    return <span style={labelStyle}>{text}</span>
  }

  return (
    <Form layout="vertical" onSubmitCapture={handleFormSubmit} id={id}>
      <Form.Item label={label("Carreira")} required className="mb-3 w-100">
        <Select placeholder="Selecione sua carreira" bordered={false} value={form?.career} onChange={handleCareerChange} style={selectStyle}>
          {data.map(career => (
            <Option key={career.career}>{career.career}</Option>
          ))}{" "}
        </Select>
      </Form.Item>

      {regime && (
        <Form.Item label={label("Regime")} required className="mb-3 w-100" style={labelStyle}>
          <Select placeholder="Selecione seu regime" bordered={false} value={form?.regime} onChange={handleRegimeChange} style={selectStyle}>
            {selectedCareer.regime.map(regime => (
              <Option key={regime}>{regime}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {sphere && (
        <Form.Item label={label("Esfera")} required className="mb-3 w-100" style={labelStyle}>
          <Select placeholder="Selecione uma opção..." bordered={false} value={form?.sphere} onChange={value => setForm({ ...form, sphere: value })} style={selectStyle}>
            {selectedCareer.sphere.map(sphere => (
              <Option key={sphere}>{sphere}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {hiringYear && (
        <Form.Item label={label("Ano de contratação")} required className="mb-3 w-100" style={labelStyle}>
          <Select placeholder="Selecione uma opção..." bordered={false} value={form?.hiringYear} onChange={value => setForm({ ...form, hiringYear: value })} style={selectStyle}>
            {selectedCareer.hiringYear.map(hiringYear => (
              <Option key={hiringYear}>{hiringYear}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {affiliation && (
        <Form.Item label={label("Convênio")} required className="mb-3 w-100" style={labelStyle}>
          <Select placeholder="Selecione uma opção..." bordered={false} value={form?.affiliation} onChange={value => setForm({ ...form, affiliation: value })} style={selectStyle}>
            {selectedCareer.affiliation.map(affiliation => (
              <Option key={affiliation}>{affiliation}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {organization && (
        <Form.Item label={label("Órgão")} required className="mb-3 w-100" style={labelStyle}>
          <Select placeholder="Selecione uma opção..." bordered={false} value={form?.organization} onChange={value => setForm({ ...form, organization: value })} style={selectStyle}>
            {selectedCareer.organization.map(organization => (
              <Option key={organization}>{organization}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      {state && (
        <Form.Item label={label("Estado do qual recebe o benefício")} required className="mb-3 w-100" style={labelStyle}>
          <Select placeholder="Selecione uma opção..." bordered={false} value={form?.state} onChange={value => setForm({ ...form, state: value })} style={selectStyle}>
            {selectedCareer.state.map(state => (
              <Option key={state}>{state}</Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Form.Item label={label("CPF")} required style={labelStyle}>
        <Input placeholder="000.000.000-00" style={inputStyle} value={form?.cpf} onChange={e => setForm({ ...form, cpf: e.target.value })} />
      </Form.Item>

      <Form.Item label={label("Nome completo")} required style={labelStyle}>
        <Input placeholder="Insira seu nome completo" style={inputStyle} value={form?.name} onChange={e => setForm({ ...form, name: e.target.value })} />
      </Form.Item>

      <Form.Item label={label("Data de nascimento")} required style={labelStyle}>
        <Input placeholder="DD/MM/AAAA" style={inputStyle} value={form?.birthDate} onChange={e => setForm({ ...form, birthDate: e.target.value })} />
      </Form.Item>

      <Form.Item label={label("E-mail")} required style={labelStyle}>
        <Input placeholder="Insira seu e-mail" style={inputStyle} value={form?.email} onChange={e => setForm({ ...form, email: e.target.value })} />
      </Form.Item>

      <Form.Item label={label("Celular")} required style={labelStyle}>
        <Input placeholder="(00) 00000-0000" style={inputStyle} value={form?.phone} onChange={e => setForm({ ...form, phone: e.target.value })} />
      </Form.Item>
    </Form>
  )
}

export default DynamicForm
