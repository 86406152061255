import React from "react"
import MediaQueries from "../../utils/MediaQueries"

// image
import Culture1Image from "../../assets/images/culture1-image.png"
import Culture2Image from "../../assets/images/culture2-image.png"
import Culture3Image from "../../assets/images/culture3-image.png"
import Culture4Image from "../../assets/images/culture4-image.png"
import Culture5Image from "../../assets/images/culture5-image.png"
import Culture6Image from "../../assets/images/culture6-image.png"
import Culture7Image from "../../assets/images/culture7-image.png"

import styles from "./Culture.module.scss"

const Culture = () => {
	const { isTabletOrMobile, isBigScreen } = MediaQueries()

	return (
		<div className={styles.container}>
			<div className={styles.section} style={{ maxHeight: isBigScreen && "80vh" }}>
				<div className={styles.leftContainer} style={{ maxWidth: isBigScreen && "50vw" }}>
					<h1 className={styles.title} style={{ maxWidth: isBigScreen && "40vw" }}>
						<span className={styles.titleHighlightPrimary}>Crédito </span>de forma
						<span className={styles.titleHighlightSecondary}> fácil</span> e <span className={styles.titleHighlightSecondary}>digna</span> é a nossa missão.
					</h1>
					<div className={styles.description}>
						<h5 className={styles.descriptionTitle}>MISSÃO</h5>
						<p className={styles.descriptionText}>Intermediar negociações entre quem quer vender com quem quer comprar através de uma modalidade de crédito segura e econômica, proporcionando segurança e agilidade em todo o processo.</p>
					</div>

					<div className={styles.description} style={{ marginTop: isTabletOrMobile && "20px" }}>
						<h5 className={styles.descriptionTitle}>VISÃO</h5>
						<p className={styles.descriptionText} style={{ margin: 0 }}>
							Ser o maior correspondente bancário multissetorial dentro de 5 anos. Oferecer acesso ao crédito e condições para todas as classes consumidoras, gerando oportunidade e poder de compra, promovendo assim a inclusão financeira de todos.
						</p>
					</div>
				</div>
				{!isBigScreen && <div className={styles.break}></div>}

				<div className={styles.rightContainer}>
					<img src={Culture1Image} alt="Idoso sorridente" className={styles.image} />
				</div>
			</div>

			<div className={styles.section} style={{ maxHeight: isBigScreen && "80vh" }}>
				{!isTabletOrMobile && (
					<>
						<div className={styles.rightContainer}>
							<img src={Culture2Image} alt="Mulher segurando criança no colo, ambas felizes" className={styles.image} />
						</div>
						{!isBigScreen && <div className={styles.largeBreak}></div>}
					</>
				)}

				<div className={styles.leftContainer} style={{ maxWidth: isBigScreen && "50vw" }}>
					<div className={styles.quote} style={{ maxWidth: isBigScreen && "40vw" }}>
						<h2 className={styles.sentence}>
							”A <strong>colaboração</strong> é melhor que a competição.”
						</h2>
						<div className={styles.author}>
							<p>John Nash</p>
							<p>Nobel de Economia de 1994</p>
						</div>
					</div>

					<div className={styles.description}>
						<h5 className={styles.descriptionTitle}>VALORES</h5>
						<p className={styles.descriptionText}>Nossa premissa é o atendimento ágil e objetivo, em uma busca ininterrupta pela inovação cultural de nossos parceiros.</p>
					</div>

					<div className={styles.description} style={{ marginTop: isTabletOrMobile ? "30px" : "40px" }}>
						<h5 className={styles.descriptionTitle}>PROPÓSITO E CULTURA</h5>
						<p className={styles.descriptionText} style={{ margin: 0 }}>
							Ser os responsáveis pela mudança na cultura de captação no crédito consignado. Promover a inclusão financeira em um país com mais de 40 milhões de desbancarizados, acesso ao crédito mais barato e de forma digna. Acreditamos que esse é o caminho para o desenvolvimento econômico.
						</p>
					</div>
				</div>

				{isTabletOrMobile && (
					<>
						<div className={styles.largeBreak}></div>
						<div className={styles.rightContainer}>
							<img src={Culture2Image} alt="Mulher segurando criança no colo, ambas felizes" className={styles.image} />
						</div>
					</>
				)}
			</div>

			<div className={styles.section}>
				<div className={styles.leftContainer} style={{ maxWidth: isBigScreen && "50vw" }}>
					<div className={styles.description}>
						<h5 className={styles.descriptionTitle}>IMPACTO SOCIAL </h5>
						<p className={styles.descriptionText}>Para implementar uma cultura de inclusão social, a busca pela acessibilidade de recursos significa a sobrevivência de muitas pessoas. Por isso surgiu a Empbank, uma Fintech que tem, como uns dos seus propósitos, minimizar as desigualdades sociais por meio da acessibilidade ao crédito. Queremos ser referência em contribuir com a realização dos sonhos de pessoas.</p>
						<p className={styles.descriptionText} style={{ margin: 0 }}>
							A Agenda 2030 é um plano de ação para as pessoas, o planeta e a prosperidade, que busca fortalecer a paz universal. São 17 Objetivos de Desenvolvimento Sustentável para erradicar a pobreza e promover vida digna para todos. A Empbank se compromete a cumprir esta agenda e contribuir para melhorar a vida das pessoas, agora e no futuro. Clique aqui e saiba mais sobre a Agenda 2030.
						</p>
						<div className={styles.agenda2030Container} style={{ marginBottom: isTabletOrMobile && "50px" }}>
							<div className={styles.agenda2030Images}>
								<a href="http://www.agenda2030.com.br/ods/1/" target="_blank" rel="noreferrer">
									<img src={Culture4Image} alt="Agenda 2030: Erradicação da Pobreza" />
								</a>
								<a href="http://www.agenda2030.com.br/ods/3/" target="_blank" rel="noreferrer">
									<img src={Culture5Image} alt="Agenda 2030: Saúde e Bem-estar" />
								</a>

								<a href="http://www.agenda2030.com.br/ods/5/" target="_blank" rel="noreferrer">
									<img src={Culture6Image} alt="Agenda 2030: Igualdade de Gênero" />
								</a>

								<a href="http://www.agenda2030.com.br/ods/10/" target="_blank" rel="noreferrer">
									<img src={Culture7Image} alt="Agenda 2030: Redução das Desigualdades" />
								</a>
							</div>
						</div>
					</div>
				</div>
				{!isBigScreen && <div className={styles.break}></div>}
				<div className={styles.rightContainer}>
					<img src={Culture3Image} alt="Crianças levantando um globo" className={styles.image} />
				</div>
			</div>
		</div>
	)
}

export default Culture
